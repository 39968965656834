import React, { useEffect, useState } from "react";
import { getBankName } from "../utils";
import { FsService } from "../services/fingerPrint";
import { permission } from "../AppConfig";
import { getUserInfo } from "../auth";

const Debug = () => {
  const [visitorId, setVisitorId] = useState(null);

  const getVisitorID = async () => {
    if (permission.fingerPoint.includes(getBankName())) {
      const fs = await FsService();
      const user = getUserInfo();
      if (fs) {
        setVisitorId(user.userId);
      }
    }
  };

  useEffect(() => {
    getVisitorID();
  }, []);

  return <div className="text-center mt-5">User Id: {visitorId}</div>;
};

export default Debug;
