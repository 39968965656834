import React from "react";
import { Stack, Typography } from "@mui/material";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { NavLink, useLocation } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Mixpanel } from "../../services/MixPanel";
import { getUserInfo } from "../../auth";

const BottomMenu = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const MixPanelTrackNav = (nav) => {
    const user = getUserInfo();
    Mixpanel.track("Mobile Bottom Bar", {
      userId: user.userId,
      nav_name: nav,
    });
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      borderBottom={"1px solid #F2F4F7"}
      bgcolor={"#FFFFFF"}
      boxShadow={"0px -10px 20px 0px rgba(52, 64, 84, 0.12)"}
      // paddingY={1.5}
      paddingX={2}
      className="bottomMenuBox"
      position={"fixed"}
      bottom={0}
      left={0}
      sx={{ zIndex: 1 }}
      width={"100%"}
    >
      <NavLink to="/home" 
      onClick={()=>{
        MixPanelTrackNav("Home");
      }}
      className={(navData) => (navData.isActive ? "active" : '')}>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ padding: "8px 14px" }}
          color={pathName === "/home" ? "#006FCF" : "#344054"}
          className="homeIconStroke"
        >
          {/* <img src={HomeIcon} alt="Home Icon" /> */}
          {/* <HomeOutlinedIcon /> */}
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.29301 14.0002C8.73705 15.7255 10.3032 17.0002 12.167 17.0002C14.0308 17.0002 15.5969 15.7255 16.041 14.0002M11.1847 2.76424L4.40238 8.03937C3.94901 8.39199 3.72233 8.5683 3.55902 8.7891C3.41436 8.98469 3.30659 9.20503 3.24102 9.4393C3.16699 9.70376 3.16699 9.99094 3.16699 10.5653V17.8002C3.16699 18.9203 3.16699 19.4804 3.38498 19.9082C3.57673 20.2845 3.88269 20.5905 4.25901 20.7822C4.68683 21.0002 5.24689 21.0002 6.36699 21.0002H17.967C19.0871 21.0002 19.6472 21.0002 20.075 20.7822C20.4513 20.5905 20.7573 20.2845 20.949 19.9082C21.167 19.4804 21.167 18.9203 21.167 17.8002V10.5653C21.167 9.99094 21.167 9.70376 21.093 9.4393C21.0274 9.20503 20.9196 8.98469 20.775 8.7891C20.6117 8.5683 20.385 8.39199 19.9316 8.03937L13.1493 2.76424C12.798 2.49099 12.6223 2.35436 12.4283 2.30184C12.2572 2.2555 12.0768 2.2555 11.9056 2.30184C11.7117 2.35436 11.536 2.49099 11.1847 2.76424Z"
              stroke="#344054"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Typography fontWeight={600} fontSize={"14px"} mt={1}>
            Home
          </Typography>
        </Stack>
      </NavLink>

      <NavLink to="/favorites" 
      onClick={()=>{
        MixPanelTrackNav("Favorites");
      }}      
      className={(navData) => (navData.isActive ? "active" : '')}>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ padding: "8px 14px" }}
          color={pathName === "/favorites" ? "#006FCF" : "#344054"}
        >
          <FavoriteBorderOutlinedIcon />
          <Typography
            fontWeight={600}
            fontSize={"14px"}
            // color={"#344054"}
            mt={1}
          >
            Favourites
          </Typography>
        </Stack>
      </NavLink>

      <NavLink to="/location" 
      onClick={()=>{
        MixPanelTrackNav("Map");
      }}      
      className={(navData) => (navData.isActive ? "active" : '')}>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ padding: "8px 14px" }}
          color={pathName === "/location" ? "#006FCF" : "#344054"}
        >
          <MapOutlinedIcon />
          <Typography
            fontWeight={600}
            fontSize={"14px"}
            // color={"#344054"}
            mt={1}
          >
            Map
          </Typography>
        </Stack>
      </NavLink>
    </Stack>
  );
};

export default BottomMenu;
