import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/homepage/Header";
import Recommended from "../../components/homepage/NewRecommended";
import RecommendedBlue from "../../components/homepage/RecommendedBlue";
import Footer from "../../components/homepage/Footer";
import {
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import "../../components/homepage/homepage.css";
import { favApi } from "../../services/HomeApi";
import { ToastMessage } from "../../components/ToastMessage";
import ErrorBoundary from "../../ErrorBoundary";
import BottomMenu from "../../components/homepage/BottomMenu";
import { en } from "../../appLabels/engLabels";
import AuthHeader from "../../components/headers/AuthHeader";
import { getBankName } from "../../utils";


const Fav = () => {
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = React.useState([]);
  const route = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Set the threshold width to consider the screen as mobile (adjust as needed)
      const mobileThreshold = 768;
      setIsMobile(window.innerWidth < mobileThreshold);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    getFavOffer();
  }, []);

  const getFavOffer = async () => {
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("userDetails"));
      const favBody = {
        customer_id: user.userId,
      };
      const apiRes = await favApi(favBody);

      if (apiRes.status === 200) {
        const data = apiRes.data;
        const offerData = [];
        for (const key in data) {
          const element = data[key];
          offerData.push(...element);
        }
        setOffers(offerData);
        setLoading(false);
      } else {
        setLoading(false);
        ToastMessage("Somthing went wrong!");
      }
    } catch (error) {
      setLoading(false);
      ToastMessage("Somthing went wrong!");
    }
  };
  const DetailsPage = (id, obj) => {
    const key = Object.keys(id)[0];
    const user = JSON.parse(localStorage.getItem("userDetails"));
    // Mixpanel.track("Hotel Card Selected", {
    //   userId: user.userId,
    //   offer_id: id,
    //   branch_id: key,
    //   location: "Favorite",
    // });
    route(`/details/${key}/${id[key]}`);
  };
  return (
    <>
      {getBankName()==='travx' ? <AuthHeader logo={true} /> : <Header/>}
      <Box className="viewFooter">
      <Container maxWidth="lg" style={{
        minHeight : "89vh",
        backgroundColor : getBankName()==='travx' ? "#CEE9EF" : '#fff'}}>
        <Box mb={8}>
          <Grid container mb={3}>
          {getBankName()==='travx'?             
          <Typography
              //className="brandomtxtbold"
              fontWeight={400}
              fontSize={{ xs: 12, sm: 24 }}
              color={"#4B4C4E"}
              fontFamily={"BrandonBold, sans-serif !important"}
            >
              Your favourites
          </Typography>
            :            
            <Typography
              fontWeight={600}
              fontSize={{ xs: 20, sm: 36 }}
              lineHeight={{ xs: "30px", sm: "44px" }}
              letterSpacing={"-0.72px"}
              color={"#101828"}
            >
              Your favourites
            </Typography>}
          </Grid>
          {!isMobile ? (
            <>
            {loading ? (
              <Box className="d-flex justify-content-center">
                <CircularProgress />
              </Box>
            ) : (
              <ErrorBoundary>
                {offers.length > 0 ? (
                  <Grid
                    container
                    spacing={3}
                    // justifyContent={"space-between"}
                    // className="recommendedBlueDesktop"
                  >
                    {offers.map((item, index) => {
                      return (
                        <Grid item xs={3} onClick={() => DetailsPage(item)}>
                          <Recommended data={Object.keys(item)[0]} obj={item} />
                        </Grid>
                      )
                    })}
                  </Grid>
                ) : (
                  <>
                  <Typography
                    as="p"
                    variant="h5"
                    className="brandomtxt-regular mt-3 lt-0"
                  >
                    {en.noOfferFound}
                  </Typography>
                  </>
                )}
              </ErrorBoundary>
            )}
            </>
          ) : (
            <>
            {/* <Grid container className="recommendedBlueMobile"> */}
              {loading ? (
                <Box className="d-flex justify-content-center">
                  <CircularProgress />
                </Box>
              ) : (

                <ErrorBoundary>
                  {offers.length > 0 ? (
                    <Grid
                      // container
                      // spacing={3}
                      justifyContent={"space-between"}
                      // marginTop={'10px'}
                    >
                      {offers.map((item, index) => {
                        return (
                          <Grid 
                            paddingBottom={'16px'} 
                            paddingTop={'16px'} 
                            item 
                            xs={12} 
                            onClick={() => DetailsPage(item)}
                            width={"100%"}
                            key={Object.keys(item)[0]}
                          >
                            <RecommendedBlue data={Object.keys(item)[0]} obj={item}/>
                          </Grid>
                        )
                      })}
                    </Grid>
                  ) : (
                    <>
                    <Typography
                      as="p"
                      variant="h5"
                      className="brandomtxt-regular mt-3 lt-0"
                    >
                      {en.noOfferFound}
                    </Typography>
                    </>
                  )}
                </ErrorBoundary>
              )}
            {/* </Grid> */}
            </>
          )}
        </Box>
      </Container>
      {getBankName()!=="travx"?  <Footer />:<></>}
      </Box>
      <BottomMenu />
    </>
  )
};

export default Fav;
