import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Stack,
  Toolbar,
  Typography,
  Drawer,
} from "@mui/material";
import SideBar from "../SideBar";

import Logo from "../../assets/homepage-logo.svg";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MenuIcon from "@mui/icons-material/Menu";
import "./homepage.css";
import { Link, useLocation } from "react-router-dom";
import ErrorBoundary from "../../ErrorBoundary";
import Dropdown from "../../components/Dropdown";
import { Mixpanel } from "../../services/MixPanel";

const Header = () => {
  const [sideBar, setSideBar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [relevantOffers, setRelevantOffers] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Set the threshold width to consider the screen as mobile (adjust as needed)
      const mobileThreshold = 768;
      setIsMobile(window.innerWidth < mobileThreshold);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const location = useLocation();
  const pathName = location.pathname;
  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSideBar(!sideBar);

    // const user = JSON.parse(localStorage.getItem("userDetails"));
    // Mixpanel.track("Sidebar Menu Button Clicked", {
    //   userId: user.userId,
    // });
  };
  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchValue(val);
  };

  const MixPanelTrackNav = (nav) => {
    // const user = JSON.parse(localStorage.getItem("userDetails"));
    // Mixpanel.track("Menu Items Clicked", {
    //   userId: user.userId,
    //   nav_name: nav,
    // });
  };

  return (
    <>
      <AppBar
        sx={{
          background: "#fff",
          boxShadow: "none",
          position: "inherit",
          marginBottom: "32px",
          borderBottom: "1px solid #EAECF0",
          padding: "16px 0",
        }}
        className="appbarDesktop">
        <Container maxWidth="lg">
          <Toolbar
            sx={{ justifyContent: "space-between", padding: "0 !important" }}>
            <Stack direction={"row"} alignItems={"center"}>
              <Link
                to="/home"
                onClick={() => {
                  MixPanelTrackNav("Home");
                }}>
                <img src={Logo} alt="" />
              </Link>
              <Stack direction={"row"} spacing={4} ml={5}>
                <Link
                  to="/home"
                  onClick={() => {
                    MixPanelTrackNav("Home");
                  }}>
                  <Typography
                    fontWeight={600}
                    color={pathName === "/home" ? "#006FCF" : "#475467"}>
                    Home
                  </Typography>
                </Link>
                <Link
                to="/all-brands"
                // onClick={() => {
                //   MixPanelTrackNav("All Brands");
                // }}
                >
                  <Typography
                    fontWeight={600}
                    color={pathName === "/all-brands" ? "#006FCF" : "#475467"}>
                    All Brands
                  </Typography>
                </Link>
                {/* <Link to="/location"
                onClick={()=>{
                  MixPanelTrackNav("Map");
                }}>
                  <Typography
                    fontWeight={600}
                    color={pathName === "/location" ? "#006FCF" : "#475467"}
                  >
                    Maps
                  </Typography>
                </Link> */}
                <Link
                  to="/terms"
                  onClick={() => {
                    MixPanelTrackNav("Terms");
                  }}>
                  <Typography
                    fontWeight={600}
                    color={pathName === "/terms" ? "#006FCF" : "#475467"}>
                    Terms
                  </Typography>
                </Link>
                <Link
                  to="/help"
                  onClick={() => {
                    MixPanelTrackNav("Help");
                  }}>
                  <Typography
                    fontWeight={600}
                    color={pathName === "/help" ? "#006FCF" : "#475467"}>
                    Help
                  </Typography>
                </Link>
              </Stack>
            </Stack>

            <Stack direction={"row"} alignItems={"center"} spacing={3}>
              <Box width={337} height={48} position={"relative"}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "8px",
                    top: "50%",
                    transform: "TranslateY(-50%)",
                    width: 32,
                    height: 32,
                    backgroundColor: "#006FCF",
                    borderRadius: "100px",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <SearchIcon sx={{ width: 20, height: 20 }} />
                </Box>
                <input
                  //onFocus={this.value=""}
                  type="text"
                  placeholder="Search Brands"
                  style={{
                    outline: "none",
                    background: "#FFFFFF",
                    boxShadow: "0px 1px 2px 0px rgba(31, 41, 55, 0.08)",
                    borderRadius: "1000px",
                    border: "1px solid #EAECF0",
                    width: "100%",
                    height: "100%",
                    paddingLeft: "24px",
                  }}
                  onChange={handleSearch}
                />
                {/* {searchValue ? (
                  <ErrorBoundary>
                    <Dropdown
                      data={searchValue}
                      relevantOffers={relevantOffers}
                      setSearchValue={setSearchValue}
                    />
                  </ErrorBoundary>
                ) : null} */}
              </Box>
              <Link
              // to="/favorites"
              // onClick={() => {
              //   MixPanelTrackNav("Favorites");
              // }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #D0D5DD",
                    background: "#fff",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#344054",
                    width: "146px",
                    height: "44px",
                  }}>
                  <FavoriteBorderIcon
                    sx={{
                      width: 20,
                      height: 20,
                      marginRight: "8px",
                      color: "#344054",
                    }}
                  />
                  Favourites
                </Button>
              </Link>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <AppBar
        sx={{
          background: "#fff",
          boxShadow: "none",
          position: "inherit",
          padding: "16px 0",
          borderBottom: "1px solid #EAECF0",
        }}
        className="appbarMobile">
        <Container>
          <Toolbar sx={{ justifyContent: "space-between", padding: 0 }}>
            <>
              <Link to="/home">
                <img src={Logo} alt="" />
              </Link>
            </>

            <Stack direction={"row"} spacing={3}>
              <Link to="/search-page">
                <SearchIcon />
              </Link>
              <MenuIcon onClick={toggleDrawer} />
            </Stack>
            <Drawer
              className="newDrawer"
              anchor="right"
              open={sideBar}
              onClose={toggleDrawer}>
              <SideBar close={toggleDrawer} />
            </Drawer>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
