import axios from "axios";
import {
  HomescreenAPIKey,
  OfferDetailsAPIKey,
  FeedbackAPIKey,
  FavoritesAPIKey,
  SearchAPIKey,
  categoriesAPIKey,
  cardDetailsApiKey,
  cardDeleteApiKey,
  modeMoneyApiKey,
  getOfferApi,
  offerDetailApi,
  feedApi,
  FavoritesAPI,
  categoriesAPI,
  SearchAPI,
  cardDetailsAPI,
  cardDeleteAPI,
  modeMoneyAPI,
  selectionApiKey,
  TopFavhoteApiKey,
  TopFavhoteSearchApiKey,
  TopFavhoteDetailsApiKey,
  relevancescoresApiKey,
  feedbackGetDirectionsApiKey,
  captureViewTimeApiKey,
  checkStatusPopupApiKey,
  responsePopupApiKey,
  checkFirsTimeUserApi,
  favouriteRestaurantsApi,
  initialThreeRestApi,
  detailsThreeRestApi,
  relevanceScoresApi,
  feedbackGetDirectionsApi,
  statusForPopupApi,
  responseForPopupApi,
  captureViewTimeApi,
  allBrandsApiKey,
  allBrandsApi,
  stackTrackApi,
  stackTracApiKey,
  fingerPrintLogging,
  fingerPrintLoggingKey,
  cookiyesApi,
  cookieyesApiKey,
  verifyRecaptchaApi,
  verifyRecaptchaApiKey,
  bookNowApi,
  bookNowApiKey,
  checkCookiesPresent,
  checkCookiesPresentKey,
  generateUuid,
  generateUuidKey,
  GmapKey,
  getArea,
  getAreaKey,

} from "../AppConfig";
import { errorReport } from "./helper";
import { getCordinatefromLocalStorage } from "./utility";
import { FsService, FsServicePro } from "./fingerPrint";
let cancelTokenSource;

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    errorReport(err);
  }
);

export const getOffers = async (body) => {
  const tos = JSON.parse(localStorage.getItem("tos_accepted"));

  const cordinate = getCordinatefromLocalStorage();
  try {
    const res = await axios
      .post(
        getOfferApi,
        { ...body, ...cordinate, tos_accepted: tos ? tos : 0 },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": HomescreenAPIKey,
          },
        }
      );
    return res;
  } catch (err) { }
};

export const getOfferDetails = async (body) => {
  const cordinate = getCordinatefromLocalStorage();

  try {
    const res = await axios
      .post(
        offerDetailApi,
        { ...body, ...cordinate },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": OfferDetailsAPIKey,
          },
        }
      );
    return res;
  } catch (err) { }
};

export const feedBackApi = async (body) => {
  try {
    const res = await axios
      .post(feedApi, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": FeedbackAPIKey,
        },
      });
    return res;
  } catch (err) { }
};

export const favApi = async (body) => {
  const cordinate = getCordinatefromLocalStorage();

  try {
    const res = await axios
      .post(
        FavoritesAPI,
        { ...body, ...cordinate },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": FavoritesAPIKey,
          },
        }
      );
    return res;
  } catch (err) { }
};

export const categoryApi = async (body) => {
  try {
    const res = await axios
      .post(categoriesAPI, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": categoriesAPIKey,
        },
      });
    return res;
  } catch (err) { }
};

export const searchApi = async (body) => {
  const cordinate = getCordinatefromLocalStorage();

  if (cancelTokenSource) {
    cancelTokenSource.cancel();
  }
  cancelTokenSource = axios.CancelToken.source();
  try {
    const res = await axios
      .post(
        SearchAPI,
        { ...body, ...cordinate },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": SearchAPIKey,
          },
          cancelToken: cancelTokenSource.token,
        }
      );
    return res;
  } catch (err) { }
};

export const getAllOfferOfCategory = async (body) => {
  let allPromise = [];
  const cordinate = getCordinatefromLocalStorage();

  try {
    const res = await axios
      .post(categoriesAPI, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": categoriesAPIKey,
        },
      });
    const catRes = res;
    let catResult = catRes.data;
    catResult.forEach((ele) => {
      let promiseRes = getOffers({
        ...body,
        ...cordinate,
        category: ele.category_name === "Restaurants" ? "Dining" : ele.category_name,
      });
      allPromise.push(promiseRes);
    });
    try {
      const daat = await Promise.all(allPromise);
      return daat;
    } catch (err) { }
  } catch (err_1) { }
};

export const cartDetailsApi = async (body) => {
  try {
    const res = await axios
      .post(cardDetailsAPI, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": cardDetailsApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const cartDeleteApi = async (body) => {
  try {
    const res = await axios
      .post(cardDeleteAPI, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": cardDeleteApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const moneyModeApi = async (body) => {
  try {
    const res = await axios
      .post(modeMoneyAPI, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": modeMoneyApiKey,
        },
      });
    return res;
  } catch (err) { }
};

// Amex apis

export const selectionApi = async (body) => {
  try {
    const res = await axios
      .post(checkFirsTimeUserApi, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": selectionApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const topFavHotelApi = async (body) => {
  try {
    const res = await axios
      .post(favouriteRestaurantsApi, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": TopFavhoteApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const topFavSearchApi = async (body) => {
  try {
    const res = await axios
      .post(initialThreeRestApi, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": TopFavhoteSearchApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const topFavDetailApi = async (body) => {
  try {
    const res = await axios
      .post(detailsThreeRestApi, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": TopFavhoteDetailsApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const homeFirstApi = async (body) => {
  try {
    const res = await axios
      .post(relevanceScoresApi, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": relevancescoresApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const beforeGetDirectionApi = (body) => {
  axios
    .post(feedbackGetDirectionsApi, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": feedbackGetDirectionsApiKey,
      },
    })
    .then((res) => res)
    .catch((err) => {
      // throw new Error(err);
    });
};

export const viewPortApi = (body) => {
  axios
    .post(captureViewTimeApi, body, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": captureViewTimeApiKey,
      },
    })
    .then((res) => res)
    .catch((err) => {
      // throw new Error(err);
    });
};
export const allBrandsApis = (obj) => {
  try {
    return axios
      .post(allBrandsApi, obj, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": allBrandsApiKey,
        },
      })
      .then((res) => res)
      .catch((err) => {
        // throw new Error(err);
      });
  } catch (error) {}
};
export const apiStackTrack = async (obj) => {
  try {
    const res = await axios
      .post(stackTrackApi, obj, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": stackTracApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const amxRegisterCheckApi = async (body) => {
  try {
    const res = await axios
      .post(statusForPopupApi, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": checkStatusPopupApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const amxResponseApi = async (body) => {
  try {
    const res = await axios
      .post(responseForPopupApi, body, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": responsePopupApiKey,
        },
      });
    return res;
  } catch (err) { }
};

export const fingerPrintLoggingApi = async () => {
  const user = await FsService();
  let proUserId = localStorage.getItem("proUserId");
  if (!proUserId) {
    const proUser = await FsServicePro();
    proUserId = proUser?.userId;
    if (proUserId) {
      localStorage.setItem("proUserId", proUserId);
    }
  }

  return axios
    .post(
      fingerPrintLogging,
      {
        open_source_fp: user?.userId || "NA",
        pro_fp: proUserId || "NA",
        fp_detailed_object: user?.components || {},
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": fingerPrintLoggingKey,
        },
      }
    )
    .then((res) => res)
    .catch((err) => {
      // throw new Error(err);
    });
};

export const getCookieyesData = async (data) => {
  try {
    const res = await axios
      .post(cookiyesApi, data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": cookieyesApiKey,
        },
      });
  } catch (err) { }
};



export const verifyRecaptcha = async (token) => {
  try {
    const res = await axios
      .post(
        verifyRecaptchaApi,
        { response: token },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": verifyRecaptchaApiKey,
          },
        }
      );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const bookNow = async (data) => {
  try {
    const res = await axios
      .post(bookNowApi, data, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": bookNowApiKey,
        },
      });
  } catch (err) { }
};

export const checkCookiesUser = (data) => {
  return axios.post(checkCookiesPresent, data, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": checkCookiesPresentKey,
    },
  });
};

export const checkUuid = (data) => {
  return axios.post(generateUuid, data, {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": generateUuidKey,
    },
  })
};

export const getUserLocation = (data) => {
  // data = {
  //   lat: 51.2785904,
  //   lng: 1.0426512,
  // };
  // 51.5053599,-0.0314085
  // 51.5205713,-0.1593553
  // 51.4994828,-0.1058237
  // 51.2785904,1.0426512,
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.lat},${data.lng}&key=AIzaSyDsW017B-n0SKijCJV92c7JmwxKEU1zlYg`;
  // const url = `https://maps.googleapis.com/maps/api/geocode/json?address=131001&sensor=false&key=AIzaSyDsW017B-n0SKijCJV92c7JmwxKEU1zlYg`

  return axios.get(url)
};

export const getAreaApi = async (body) => {
  try {
    const res = await axios
      .post(getArea, {}, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": getAreaKey,
        },
      });
    return res;
  } catch (err) { }
};




