import { Box, Typography } from "@mui/material";
import React from "react";
import { Contain } from "../styles/styles";

const Logout = () => {
  return (
    <Contain maxWidth="xl" sx={{ backgroundColor: "neutral.main", paddingTop: "" }}>
      <Box
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className="accpt-box text-center p-4 mt-0">
          <Typography as="h4" variant="h4" className="brandombold">
            Sign out successful
          </Typography>
        </Box>
      </Box>
    </Contain>
  );
};

export default Logout;
