import React, { useEffect, useState } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import "./homepage.css";
import { GeoLocationService } from "../../services/GeoLocationService";
import { getUserLocation } from "../../services/HomeApi";
import ErrorBoundary from "../../ErrorBoundary";
import Dropdown from "../../components/Dropdown";
import { ToastMessage } from "../../components/ToastMessage";
import { getUserInfo } from "../../auth";
import { amxResponseApi } from "../../services/HomeApi";
import { Link } from "react-router-dom";
import { getBankName } from "../../utils";

const GetDiscount = (props) => {
  const [loc, setloc] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [relevantOffers, setRelevantOffers] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Set the threshold width to consider the screen as mobile (adjust as needed)
      const mobileThreshold = 768;
      setIsMobile(window.innerWidth < mobileThreshold);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getLocation();
  }, []);
  const getLocation = async () => {
    try {
      const position = await GeoLocationService();
      if (position) {
        let cord = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        localStorage.setItem("geoLocation", JSON.stringify(cord));
      }
      const response = await getUserLocation({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      const data = await response.data;
      if (data?.status === "OK") {
        if (data.results.length > 0) {
          const addressComponents = data.results[0].address_components;
          let postalCode = null;
          for (const component of addressComponents) {
            const types = component.types;
            if (types.includes("postal_town")) {
              postalCode = component.long_name;
              break;
            }
          }
          if (postalCode) {
            setloc(postalCode);
            return { status: "Success", postalCode };
          } else {
            // ToastMessage("Error fetching location.");
            return "Postal code not found in the address components.";
          }
        } else {
          // ToastMessage("Error fetching location.");
          return "No results found for the provided coordinates.";
        }
      } else {
        // ToastMessage("Error fetching location.");
        return "Error: Unable to retrieve data from the API.";
      }
    } catch (error) {
      // ToastMessage("Error fetching area.");
      console.log(error);
      return "Error fetching area.";
    }
  };
  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchValue(val);
  };
  const getGeoLocation = async () => {
    const res = await getLocation();
    if (res && res?.status === "Success") {
      props.updateOffer(res.postalCode);
      ToastMessage("Your location successfully updated.", true);
    } else if (res) {
      props.updateOffer("");
      ToastMessage("Your current location is not serviceable.");
    }
  };
  const sendPopUpData = async (data) => {
    try {
      const user = getUserInfo();
      const sendBody = {
        customer_id: user.userId,
        popup_response: data,
        dont_ask_me_again_response: data,
      };
      if (!data) {
        var windowRef = window.open();
      }
      const res = await amxResponseApi(sendBody);

      if (res && res.status === 200) {
        if (res.data.redirect_website) {
          if (!data) {
            windowRef.location = res.data.redirect_website;
          }
        }
      }
    } catch (error) {}
  };
  return (
    <>
      {getBankName() === "travx" ? (
        <></>
      ) : (
        <Container maxWidth="lg" className="removeContainerPadding">
          <Box
            borderRadius={{ xs: "none", sm: "12px" }}
            sx={{
              background: "linear-gradient(27deg, #66A9E2 0%, #338CD9 100%)",
            }}
            paddingY={5}
            paddingX={2}
            className="text-center">
            <Typography
              variant="h2"
              fontWeight={600}
              fontSize={{ sm: 30, xs: 20 }}
              color={"white"}>
              10% cashback on all featured gift cards until January 31, 2023*
            </Typography>

            {!isMobile ? (
              <Stack
                direction={"row"}
                justifyContent={"center"}
                spacing={2}
                mt={3}
                className="getDiscountSearchDesktop">
                <Box width={360} height={48} position={"relative"}>
                  <SearchIcon
                    sx={{
                      position: "absolute",
                      left: "14px",
                      top: "14px",
                      width: 20,
                      height: 20,
                      color: "#667085",
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Search Brands"
                    style={{
                      outline: "none",
                      background: "#FFFFFF",
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      borderRadius: "8px",
                      border: "1px solid #D0D5DD",
                      width: "100%",
                      height: "100%",
                      paddingLeft: "42px",
                    }}
                    //onChange={handleSearch}
                  />
                  {/* {searchValue ? (
                    <ErrorBoundary>
                      <Dropdown
                        data={searchValue}
                        relevantOffers={relevantOffers}
                        setSearchValue={setSearchValue}
                      />
                    </ErrorBoundary>
                  ) : null} */}
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#006FCF",
                    borderRadius: "8px",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    fontWeight: 600,
                    fontSize: 16,
                    padding: "10px 20px",
                    width: "95px",
                  }}>
                  Search
                </Button>
              </Stack>
            ) : (
              <Stack
                direction={"row"}
                justifyContent={"center"}
                spacing={2}
                mt={3}
                className="getDiscountSearchMobile">
                <Box width={360} height={48} position={"relative"}>
                  <SearchIcon
                    sx={{
                      position: "absolute",
                      left: "14px",
                      top: "12px",
                      width: 20,
                      height: 20,
                      color: "#667085",
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Restaurant, Cuisine or Area"
                    style={{
                      outline: "none",
                      background: "#FFFFFF",
                      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      borderRadius: "8px",
                      border: "1px solid #D0D5DD",
                      width: "100%",
                      height: 44,
                      paddingLeft: "42px",
                      fontSize: 14,
                    }}
                    //onChange={handleSearch}
                  />
                  {/* {searchValue ? (
                    <ErrorBoundary>
                      <Dropdown
                        data={searchValue}
                        relevantOffers={relevantOffers}
                        setSearchValue={setSearchValue}
                      />
                    </ErrorBoundary>
                  ) : null} */}
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#006FCF",
                    borderRadius: "8px",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    minWidth: 44,
                    width: 44,
                    height: 44,
                  }}>
                  <SearchIcon sx={{ width: 20, height: 20 }} />
                </Button>
              </Stack>
            )}
            <Typography
              fontSize={10}
              color={"white"}
              textAlign={"left"}
              bottom={"-28px"}
              position={"relative"}>
              *enrolment required, eligible cardmembers only
            </Typography>
          </Box>
        </Container>
      )}

      <Container>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          spacing={1.5}
          borderBottom={"1px solid #EAECF0"}
          paddingY={4}
          marginBottom={4}>
          <Typography fontSize={{ xs: 16, sm: 18 }} color={"#475467"}>
            {/* {loc
              ? `It looks like you're in ${loc}. Not correct?`
              : `Find ${
                  getBankName() === "travx" ? "outlets" : "resturants"
                } near you`} */}
            It looks like you're in London. Not correct?
          </Typography>
          <Stack
            direction="row"
            alignItems={"center"}
            spacing={1}
            onClick={getGeoLocation}
            sx={{ cursor: "pointer" }}>
            {/* <div onClick={getLocation}> */}
            <NearMeOutlinedIcon
              sx={{ color: "#006FCF" }}
              className="getDiscountLocation"
            />
            <Typography
              fontWeight={600}
              fontSize={{ xs: 16, sm: 18 }}
              color={"#006FCF"}
              className="hoverUnderline">
              Get current location
            </Typography>
            {/* </div> */}
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default GetDiscount;
