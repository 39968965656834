import React from "react";
import { getUserInfo } from "./auth";
import { ToastMessage } from "./components/ToastMessage";
import { apiStackTrack } from "./services/HomeApi";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    const user = getUserInfo();
    const body = {
      customer_id: user.userId ? user.userId : "",
      stack_trace: JSON.stringify(errorInfo),
    };
    const track = async () => {
      const stk = await apiStackTrack(body);
      if (stk) {
        ToastMessage("Error has been reported");
      } else {
        ToastMessage("Somthing went wrong!");
      }
    };
    track();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h4 className="brandombold lt-0">Something went wrong.</h4>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
