import React, { useState, useEffect } from "react";
import Header from "../../components/homepage/Header";
import Recommended from "../../components/homepage/NewRecommended";
import Footer from "../../components/homepage/Footer";
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import "../../components/homepage/homepage.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../auth";
import { getBankName } from "../../utils";
import { getOffers } from "../../services/HomeApi";
import ErrorBoundary from "../../ErrorBoundary";
import { Mixpanel } from "../../services/MixPanel";
import InfiniteScroll from "react-infinite-scroll-component";
import BottomMenu from "../../components/homepage/BottomMenu";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import CusinesDropDown from "../../components/homepage/CusinesDropdown";
import PriceDropDown from "../../components/homepage/PriceDropdown";
import AreaDropDown from "../../components/homepage/AreaDropDown";

const ListView = () => {
  const [loading, setLoading] = useState(false);
  const [rec, setRec] = useState([]);
  const route = useNavigate();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [sortBy, setSortBy] = useState("Relevance");
  let [pageIndex, setPageIndex] = useState(
    JSON.parse(localStorage.getItem("RecpaginationIndex"))
  );
  const [rowLocal, setRowLocal] = useState(
    JSON.parse(localStorage.getItem("RecpaginationRow"))
  );

  const [cusines, setCusines] = React.useState("");
  const [area, setArea] = React.useState("");
  const [price, setPrice] = React.useState("");

  const [brandName, setBrandName] = useState("");
  const { search } = useLocation();

  useEffect(() => {
    successLocation();
  }, [cusines, area, price]);
  const successLocation = async (val) => {
    // setLoading(true);
    try {
      const user = getUserInfo();
      const location = window.location.search.split("=")[1];
      if (getBankName() !== "travx") {
        const name = new URLSearchParams(search).get("brand");
        setBrandName(name);
      }
      const type = window.location.search.split("=")[0];

      const offerBody = {
        customer_id: user.userId,
        view_all: true,
        // for_map: true,
        sorted_by: val ? val : sortBy,
        page: pageIndex ? pageIndex : page,
        limit: rowLocal ? parseInt(rowLocal) : parseInt(rows),
        location: type === "?location" ? location.replace("-", " ") : undefined,
        pincode: type === "?pincode" ? location : undefined,
        offer_id: type === "?offer" ? location.split("&")[0] : undefined,
      };

      if (cusines) {
        offerBody.category = cusines;
      } 
      if (area) {
        offerBody.location = area;
      } 
      if (price) {
        offerBody.price =  price;
      }
      const apiRes = await getOffers(offerBody);

      if (apiRes.status === 200) {
        // setRec([...apiRes.data.offer_rest_mapping]);
        // setPage(page+1)
        setRec((prevItems) => [
          ...prevItems,
          ...apiRes.data.offer_rest_mapping,
        ]);
        setCount(apiRes.data.count);
        setPage((prevPage) => prevPage + 1);
        setLoading(false);
      }
    } catch (error) {}
  };
  const DetailsPage = (id) => {
    const key = Object.keys(id)[0];
    const user = JSON.parse(localStorage.getItem("userDetails"));
    Mixpanel.track("Hotel Card Selected", {
      userId: user.userId,
      offer_id: id,
      branch_id: key,
      location: "Offers All",
    });
    route(`/details/${key}/${id[key]}`);
  };

  const updateCusines = (val)=> {
    setCusines(val)
  }
  const updatePrice = (val)=> {
    setPrice(val)
  }
  const updateArea = (val)=> {
    setArea(val)
  }
  return (
    <Box position={"relative"}>
      <Header />

      <Box
        borderBottom={"1px solid #F2F4F7"}
        marginBottom={4}
        paddingY={1.5}
        width={"100%"}
        overflow={"auto"}
        whiteSpace={"nowrap"}
        className="listViewDropdowns"
      >
        <Container maxWidth="lg">
            <CusinesDropDown updateCusines={updateCusines}/>
            <PriceDropDown updatePrice={updatePrice}/>
            {/* <AreaDropDown updateArea={updateArea}/> */}
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Box>
          <>
            {/* {recent.length > 0 ? ( */}
            <>
              <InfiniteScroll
                dataLength={count}
                next={successLocation}
                hasMore={true} // Replace with a condition based on your data source
                loader={
                  count ? (
                    <Box className="d-flex justify-content-center my-5">
                      <CircularProgress />
                    </Box>
                  ) : (
                    ""
                  )
                }
                endMessage={<p>No more data to load.</p>}
              >
                <Grid container spacing={3}>
                  {rec.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={3}
                        // key={Object.keys(item)[0]}
                        onClick={() => DetailsPage(item)}
                        className="mb-4 card-grid"
                      >
                        <Recommended data={Object.keys(item)[0]} obj={item} />
                      </Grid>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
            </>
            {/* ) : (
              <Typography
                as="p"
                variant="body4"
                className="brandomtxt-regular lt-0 text-center mt-4"
              >
                {en.noTransaction}
              </Typography>
            )} */}
          </>
        </Box>
      </Container>

      <Footer />

      <BottomMenu />

      <Link to="/location">
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          borderRadius={"8px"}
          border={"1px solid rgba(0, 44, 83, 0.80)"}
          bgcolor={"rgba(0, 44, 83, 0.80)"}
          boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
          gap={1.5}
          color={"white"}
          width={277}
          height={60}
          position={"sticky"}
          bottom={"10%"}
          left={"50%"}
          sx={{ zIndex: 2, transform: "translateX(-50%)" }}
          className="showMapFloating"
        >
          <MapOutlinedIcon />
          <Typography fontWeight={600} fontSize={18}>
            Show Map
          </Typography>
        </Stack>
      </Link>
    </Box>
  );
};

export default ListView;
