import React, { useEffect, useState } from "react";
import Header from "./Header";
import { Box, Container } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { ToastMessage } from "../ToastMessage";
import { categoryApi } from "../../services/HomeApi";
import ErrorBoundary from "../../ErrorBoundary";
import Dropdown from "../Dropdown";
import BottomMenu from "./BottomMenu";
import { getBankName } from "../../utils";
import AuthHeader from "../headers/AuthHeader";

const SearchPage = () => {
  const route = useNavigate();
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [relevantOffers, setRelevantOffers] = useState([]);

  useEffect(() => {
    localStorage.setItem("CatpaginationIndex", JSON.stringify(1));
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      setLoading(true);
      const catRes = await categoryApi({ view_all: false });

      if (catRes.status === 200) {
        setCategory([...catRes.data]);
        setLoading(false);
      } else {
        ToastMessage("Somthing went wrong!", true);
        setLoading(false);
      }
    } catch (error) {}
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchValue(val);
  };

  const catRoute = (id) => {
    if (id === "Vegetarian") {
      id = "Vegetarian Friendly"
    }
    if (id === "Steaks") {
      id = "steak";
    }
    localStorage.setItem("activeCategory", JSON.stringify(id));
    const permaLink = id.toLowerCase().replace(/[^A-Z0-9]+/gi, "-");
    route(`/offers/category/${permaLink}`);
  };

  return (
    <>
      {getBankName() === 'travx' ? <AuthHeader logo={true}/> : <Header />}
      <Box className="viewFooter">
      <Container>
        <Box width={"100%"} height={48} position={"relative"} mt={2}>
          <SearchIcon
            sx={{
              position: "absolute",
              left: "14px",
              top: "14px",
              width: 20,
              height: 20,
              color: "#667085",
            }}
          />
          <input
            type="text"
            placeholder="Restaurant, Cuisine or Area"
            style={{
              outline: "none",
              background: "#FFFFFF",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              borderRadius: "8px",
              border: "1px solid #D0D5DD",
              width: "100%",
              height: "100%",
              paddingLeft: "42px",
            }}
            value={searchValue}
            onChange={handleSearch}
          />
          {searchValue ? (
            <ErrorBoundary>
              <Dropdown data={searchValue} relevantOffers={relevantOffers}  setSearchValue={setSearchValue}/>
            </ErrorBoundary>
          ) : null}
        </Box>
      </Container>
      </Box>
      <BottomMenu />
    </>
  );
};

export default SearchPage;
