import mixpanel from 'mixpanel-browser';
// const concent = localStorage.getItem('cookieYesConcent') == 'true';
const concent = false
if (concent)
mixpanel.init('f7a262811e162452da6af47145c78073');

// let env_check = process.env.NODE_ENV === 'production';
let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check && concent) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check && concent) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check && concent) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check && concent) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;