import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import FavoriteIcon from "@mui/icons-material/Favorite";

import { getUserInfo } from "../../auth/index";
import { getOfferDetails } from "../../services/HomeApi";
import { ToastMessage } from "../ToastMessage";
import ErrorBoundary from "../../ErrorBoundary";
import { withCommas } from "../../services/utility";
import FeedBack from "../../components/FeedBack";
import Image from "../Image";

const RecommendedBlue = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    setIsVisible(inView);
  }, [inView]);

  const [offerInfo, setOfferInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let branchID = props.obj[props.data];
    getDetails(props.data, branchID);
  }, [props.data]);

  const getDetails = async (id, branchID) => {
    try {
      setLoading(true);
      const user = getUserInfo();
      const obj = {
        customer_id: user.userId,
        offer_id: id,
        branch_id: branchID ? branchID : "",
      };

      if (props.getThumbnail) {
        obj.display_thumbnail = props.getThumbnail;
      }

      const apiRes = await getOfferDetails(obj);
      if (apiRes.status === 200) {
        setOfferInfo({ ...apiRes.data });
        if (props.card === "small") {
          let offerLocation = JSON.parse(localStorage.getItem("offerLocation"));
          let newLocation = {};
          newLocation[id] = {
            lat: apiRes.data.branch_latitude,
            lng: apiRes.data.branch_longitude,
          };
          if (offerLocation) {
            localStorage.setItem(
              "offerLocation",
              JSON.stringify({ ...offerLocation, ...newLocation })
            );
          } else {
            localStorage.setItem("offerLocation", JSON.stringify(newLocation));
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        ToastMessage("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const GetCusine = (item, index = undefined) => {
    if (Object.keys(offerInfo).length && index === 0)
      return item.cuisines_list[index];
    if (Object.keys(offerInfo).length && item.cuisines_list[1]) {
      let t = [item.cuisines_list[0], item.cuisines_list[1]];
      return t.join(", ");
    } else {
      return item.cuisines_list.join(", ");
    }
  };
  return (
    <>
      {Object.keys(offerInfo).length === 0 ? (
        <Box className="d-flex justify-content-center">
          {loading ? (
            <CircularProgress />
          ) : (
            <Typography
              as="p"
              variant="h5"
              className="brandomtxt-regular mt-3 lt-0"
            >
              <CircularProgress />
            </Typography>
          )}
        </Box>
      ) : (
        <ErrorBoundary>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            // alignContent={"baseline"}
            className="recommendedBlue"
            style={{cursor: "pointer"}}
          >
            <Stack direction={"row"}>
              <Box position={"relative"} width={"32.54vw"} marginRight={"10px"}>
              <div ref={ref}>
                {isVisible ? (
                  // <Image
                  //   src={offerInfo.offer_creatives}
                  //   alt={offerInfo.brand_name}
                  //   loading="lazy"
                  //   className={
                  //     props.card === "small"
                  //       ? `offer-card-image_small`
                  //       : `offer-card-image_small`
                  //   }
                  // />
                  <img
                    src={offerInfo.offer_creatives}
                    alt={offerInfo.brand_name}
                    style={{ borderRadius: "12px" }}
                  />

                ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#f3f3f3",
                      }}
                    ></div>
                  )}
                </div>
                <Box
                  bgcolor={"#F2F4F7"}
                  borderRadius={"16px"}
                  paddingX={"10px"}
                  paddingY={"2px"}
                  fontWeight={500}
                  fontSize={"13px"}
                  color={"#344054"}
                  position={"absolute"}
                  top={"8px"}
                  left={"50%"}
                  minWidth={111}
                  className="recommendedBlueDiscount"
                >
                  {offerInfo.discount_percentage}% Cashback
                </Box>
              </Box>

              <Box>
                <Typography
                  fontSize={14}
                  fontWeight={600}
                  color={"black"}
                  mb={0.5}
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                  textOverflow={"ellipsis"}
                  maxWidth={"47vw"}
                >
                  {offerInfo.brand_name}
                </Typography>

                {offerInfo.restaurant_rating &&  offerInfo.restaurant_rating >= 4 && offerInfo.num_reviews ? (
                  <Stack direction={"row"} alignItems="center" mb={0.5}>
                    <StarIcon sx={{ fontSize: "16px", color: "black" }} />
                    <Typography
                      fontSize={14}
                      color={"black"}
                      sx={{ paddingX: "4px" }}
                    >
                      {offerInfo.restaurant_rating}
                    </Typography>
                    <Typography fontSize={14} color={"black"}>
                      ({withCommas(offerInfo.num_reviews)})
                    </Typography>
                  </Stack>
                ) : null}

                <Typography fontSize={14} color={"#344054"}>
                  {offerInfo.view_all_branches
                    ? ` ${offerInfo.branch_name}`
                    : ""}
                </Typography>

                <Typography fontSize={14} color={"#344054"} mt={0.5}>
                  {GetCusine(offerInfo, 1)}
                </Typography>

                <Stack direction="row" alignItems="center" mt={0.5}>
                  <Typography fontSize={14} color={"#344054"}>
                    {withCommas(offerInfo.distance_from_user)} miles
                  </Typography>
                  <Box
                    width={2}
                    height={2}
                    bgcolor={"#344054"}
                    borderRadius={"50%"}
                    marginX={0.5}
                  ></Box>
                  <Typography fontSize={14} fontWeight={600} color={"black"}>
                    {offerInfo.price_range}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            <Box>
              <FeedBack
                offerId={props.data}
                branchId={props.obj[props.data]}
                favourite={offerInfo.favourite}
                // like={offer.like}
                // dislike={offer.dislike}
              />
            </Box>
          </Stack>
        </ErrorBoundary>
      )}
    </>
  );
};

export default RecommendedBlue;
