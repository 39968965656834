// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* the form conatiner css styles */
.emailNnameContainer input:focus {
  color: "green";
}

.input-container {
  position: relative;
  margin: 50px;
}

.input-container input {
  border: 2px solid #ccc;
  padding: 10px;
  width: 300px;
  outline: none;
  font-size: 16px;
}

.input-container input:focus + label {
  transform: translateY(-35px);
  font-size: 12px;
  background-color: "red";
  color: #333;
}

.input-container label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  font-size: 16px;
  color: #999;
  background-color: "red";
}
`, "",{"version":3,"sources":["webpack://./src/components/homepage/details.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,4BAA4B;EAC5B,eAAe;EACf,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,yBAAyB;EACzB,eAAe;EACf,WAAW;EACX,uBAAuB;AACzB","sourcesContent":["/* the form conatiner css styles */\n.emailNnameContainer input:focus {\n  color: \"green\";\n}\n\n.input-container {\n  position: relative;\n  margin: 50px;\n}\n\n.input-container input {\n  border: 2px solid #ccc;\n  padding: 10px;\n  width: 300px;\n  outline: none;\n  font-size: 16px;\n}\n\n.input-container input:focus + label {\n  transform: translateY(-35px);\n  font-size: 12px;\n  background-color: \"red\";\n  color: #333;\n}\n\n.input-container label {\n  position: absolute;\n  left: 10px;\n  top: 50%;\n  transform: translateY(-50%);\n  transition: all 0.3s ease;\n  font-size: 16px;\n  color: #999;\n  background-color: \"red\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
