import { useEffect, useState } from "react";
import Router from "./router";

function App() {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Router />
    </>
  );
}

export default App;
