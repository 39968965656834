export const validatorApi = process.env.REACT_APP_URL_VALIDATOR_URL;
export const UrlValidatorApiKey = process.env.REACT_APP_URL_VALIDATOR_API_KEY;

export const signupUrlApi = process.env.REACT_APP_SIGN_UP_URL;
export const signupKey = process.env.REACT_APP_SIGN_UP_KEY;

export const loginUrlApi = process.env.REACT_APP_LOGIN_URL;
export const loginKey = process.env.REACT_APP_LOGIN_KEY;

export const forgotApi = process.env.REACT_APP_FORGOT_URL;
export const forgotKey = process.env.REACT_APP_FORGOT_KEY;

export const passwordRestApi = process.env.REACT_APP_PASSWORD_RESET_URL;
export const passwordReset = process.env.REACT_APP_PASSWORD_RESET_KEY;

export const customerIdApi = process.env.REACT_APP_CUSTOMER_ID_URL;
export const customerIdKey = process.env.REACT_APP_CUSTOMER_ID_KEY;

export const editProfileApiUrl = process.env.REACT_APP_EDIT_PROFILE_URL;
export const EditProfileApiAPIKey = process.env.REACT_APP_EDIT_PROFILE_API_KEY;

export const getProfileApi = process.env.REACT_APP_GET_PROFILE_URL;
export const getProfileApiAPIKey = process.env.REACT_APP_GET_PROFILE_API_KEY;

export const contactSupportApi = process.env.REACT_APP_CONTACT_SUPPORT_URL;
export const ContactSupportAPIKei =
  process.env.REACT_APP_CONTACT_SUPPORT_API_KEY;

// After Home

export const getOfferApi = process.env.REACT_APP_HOME_SCREEN_URL;
export const HomescreenAPIKey = process.env.REACT_APP_HOME_SCREEN_API_KEY;

export const offerDetailApi = process.env.REACT_APP_OFFER_DETAILS_URL;
export const OfferDetailsAPIKey = process.env.REACT_APP_OFFER_DETAILS_API_KEY;

export const feedApi = process.env.REACT_APP_FEEDBACK_URL;
export const FeedbackAPIKey = process.env.REACT_APP_FEEDBACK_API_KEY;

export const FavoritesAPI = process.env.REACT_APP_FAVOURITES_URL;
export const FavoritesAPIKey = process.env.REACT_APP_FAVOURITES_API_KEY;

export const cardDetailsAPI = process.env.REACT_APP_CARD_DETAILS_URL;
export const cardDetailsApiKey = process.env.REACT_APP_CARD_DETAILS_API_KEY;

export const cardDeleteAPI = process.env.REACT_APP_CARD_DELETE_URL;
export const cardDeleteApiKey = process.env.REACT_APP_CARD_DELETE_API_KEY;

export const SearchAPI = process.env.REACT_APP_SEARCH_URL;
export const SearchAPIKey = process.env.REACT_APP_SEARCH_API_KEY;

export const categoriesAPI = process.env.REACT_APP_CATEGORIES_URL;
export const categoriesAPIKey = process.env.REACT_APP_CATEGORIES_API_KEY;

export const modeMoneyAPI = process.env.REACT_APP_CATEGORIES_URL;
export const modeMoneyApiKey = process.env.REACT_APP_MODE_MONEY_API_KEY;

// Savings

export const SavingsSummaryAPI = process.env.REACT_APP_SAVINGS_SUMMARY_URL;
export const SavingsSummaryAPIKey =
  process.env.REACT_APP_SAVINGS_SUMMARY_API_KEY;

export const TransactionsHistoryAPI =
  process.env.REACT_APP_TRANSACTION_HISTOY_URL;
export const TransactionsHistoryAPIKey =
  process.env.REACT_APP_TRANSACTION_HISTOY_API_KEY;
export const CashbackHistoryAPIKey =
  process.env.REACT_APP_CASHBACK_HISTORY_API_KEY;

export const payoutFlowAPI = process.env.REACT_APP_PAYOUT_FLOW_URL;
export const payoutFlowApiKey = process.env.REACT_APP_PAYOUT_FLOW_API_KEY;

export const bankAccAddedKeyAPI = process.env.REACT_APP_ACCOUNT_ADDED_URL;
export const bankAccAddedKeyApiKey =
  process.env.REACT_APP_ACCOUNT_ADDED_API_KEY;

export const payoutAddCounterpartyAPI =
  process.env.REACT_APP_PAYOUT_ADD_COUNTER_PARTY_URL;
export const payoutAddCounterpartyApiKey =
  process.env.REACT_APP_PAYOUT_ADD_COUNTER_PARTY_API_KEY;

export const payoutMakePaymentAPI =
  process.env.REACT_APP_PAYOUT_MAKE_PAYEMENT_URL;
export const payoutMakePaymentApiKey =
  process.env.REACT_APP_PAYOUT_MAKE_PAYEMENT_API_KEY;

export const bankAccLastAPI = process.env.REACT_APP_BANK_ACC_LAST_URL;
export const bankAccLastApiKey = process.env.REACT_APP_BANK_ACC_LAST_API_KEY;

// Amex api and keys

export const checkFirsTimeUserApi = process.env.REACT_APP_SELECTION_URL;
export const selectionApiKey = process.env.REACT_APP_SELECTION_API_KEY;

export const favouriteRestaurantsApi = process.env.REACT_APP_TOP_FAV_HOTEL_URL;
export const TopFavhoteApiKey = process.env.REACT_APP_TOP_FAV_HOTEL_API_KEY;

export const initialThreeRestApi =
  process.env.REACT_APP_TOP_FAV_HOTEL_SEARCH_URL;
export const TopFavhoteSearchApiKey =
  process.env.REACT_APP_TOP_FAV_HOTEL_SEARCH_API_KEY;

export const detailsThreeRestApi =
  process.env.REACT_APP_TOP_FAV_HOTEL_DETAILS_URL;
export const TopFavhoteDetailsApiKey =
  process.env.REACT_APP_TOP_FAV_HOTEL_DETAILS_API_KEY;

export const relevanceScoresApi = process.env.REACT_APP_RELEVANVE_SCORE_URL;
export const relevancescoresApiKey =
  process.env.REACT_APP_RELEVANVE_SCORE_API_KEY;

export const feedbackGetDirectionsApi =
  process.env.REACT_APP_FEEDBACK_GET_DIRECTIONS_URL;
export const feedbackGetDirectionsApiKey =
  process.env.REACT_APP_FEEDBACK_GET_DIRECTIONS_API_KEY;

export const captureViewTimeApi = process.env.REACT_APP_CAPTURE_VIEW_TIME_URL;
export const captureViewTimeApiKey =
  process.env.REACT_APP_CAPTURE_VIEW_TIME_API_KEY;

export const statusForPopupApi = process.env.REACT_APP_CHECK_STATUS_POP_UP_URL;
export const checkStatusPopupApiKey =
  process.env.REACT_APP_CHECK_STATUS_POP_UP_API_KEY;

export const responseForPopupApi = process.env.REACT_APP_RESPONSE_POP_UP_URL;
export const responsePopupApiKey =
  process.env.REACT_APP_RESPONSE_POP_UP_API_KEY;

export const allBrandsApi = process.env.REACT_APP_ALL_BRANDS_URL;
export const allBrandsApiKey = process.env.REACT_APP_ALL_BRANDS_API_KEY;

export const stackTrackApi = process.env.REACT_APP_STACK_TRAC_URL;
export const stackTracApiKey = process.env.REACT_APP_STACK_TRAC_API_KEY;

export const fingerPrintLogging =
  process.env.REACT_APP_FINGER_PRINT_LOGGING_URL;
export const fingerPrintLoggingKey =
  process.env.REACT_APP_FINGER_PRINT_LOGGING_API_KEY;

/// changes for prod and stage

export const cookiyesApi = process.env.REACT_APP_COOKIEYES_URL;
export const cookieyesApiKey = process.env.REACT_APP_COOKIEYES_API_KEY;

// export const cookieyesProdApi = process.env.REACT_APP_COOKIEYES_PROD_URL;
// export const cookieyesProdApiKey = process.env.REACT_APP_COOKIEYES_PROD_API_KEY;

export const verifyRecaptchaApi = process.env.REACT_APP_VERIFY_RECAPTCHA_URL;
export const verifyRecaptchaApiKey =
  process.env.REACT_APP_VERIFY_RECAPTCHA_API_KEY;

export const bookNowApi = process.env.REACT_APP_BOOK_NOW_URL;
export const bookNowApiKey = process.env.REACT_APP_BOOK_NOW_API_KEY;

// export const bookNowProdApi = process.env.REACT_APP_BOOK_NOW_PROD_URL;
// export const bookNowProdApiKey = process.env.REACT_APP_BOOK_NOW_PROD_API_KEY;

export const checkCookiesPresent =
  process.env.REACT_APP_CHECK_COOKIES_PRESENT_URL;
export const checkCookiesPresentKey =
  process.env.REACT_APP_CHECK_COOKIES_PRESENT_API_KEY;

// export const checkProdCookiesPresent =  process.env.REACT_APP_CHECK_PROD_COOKIES_PRESENT_URL;
// export const checkProdCookiesPresentKey =  process.env.REACT_APP_CHECK_PROD_COOKIES_PRESENT_API_KEY;

export const generateUuid = process.env.REACT_APP_GENERATE_UUID_URL;
export const generateUuidKey = process.env.REACT_APP_GENERATE_UUID_API_KEY;

// export const generateProdUuid = process.env.REACT_APP_GENERATE_PROD_UUID_URL;
// export const generateProdUuidKey = process.env.REACT_APP_GENERATE_PROD_UUID_API_KEY;

export const getArea = process.env.REACT_APP_GET_AREA_URL;
export const getAreaKey = process.env.REACT_APP_GET_AREA_API_KEY;

// export const prodGetArea = process.env.REACT_APP_PROD_GET_AREA_URL;
// export const prodGetAreaKey = process.env.REACT_APP_PROD_GET_AREA_API_KEY;

export const gClientId = process.env.REACT_APP_gClientId;
export const GmapKey = process.env.REACT_APP_GMAP;
export const FIDEL_SDK_KEY = process.env.REACT_APP_FIDEL_SDK_KEY;
export const FIDEL_PROGRAM_ID = process.env.REACT_APP_FIDEL_PROGRAM_ID;
export const FIDEL_API_KEY = process.env.REACT_APP_FIDEL_API_KEY;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_SITE_KEY;
export const RECAPTCHA_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const hostList = ["hotels.travx.io", "amex.travx.io"];

export const permission = {
  fingerPoint: ["amex"],
  hotelLocationFromUrl: ["travx"],
};
