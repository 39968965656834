import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FoodType = ({ cuisinesText }) => {
  const route = useNavigate();

  const catRoute = (id) => {
    if (id === "Vegetarian") {
      id = "Vegetarian Friendly"
    }
    if (id === "Steaks") {
      id = "steak";
    }
    localStorage.setItem("CatpaginationRow", JSON.stringify(10));
    localStorage.setItem("CatpaginationIndex", JSON.stringify(1));
    localStorage.setItem("activeCategory", JSON.stringify(id));
    const permaLink = id.toLowerCase().replace(/[^A-Z0-9]+/gi, "-");
    // const user = JSON.parse(localStorage.getItem("userDetails"));
    // Mixpanel.track("Categories Selected", {
    //   userId: user.userId,
    //   view_all: props.viewAll ? true : false,
    //   selected_catagory: permaLink,
    // });
    route(`/offers/category/${permaLink}`);
  };
  return (
    <Box
      className="text-center"
      border={"1px solid #D0D5DD"}
      borderRadius={"8px"}
      boxShadow={"0px 1px 2px 0px rgba(16, 24, 40, 0.05)"}
      padding={"16px 28px"}
      onClick={() => catRoute(cuisinesText)}
      style={{cursor:'pointer'}}
    >
      {/* <Box
        width={32}
        height={32}
        borderRadius={"50%"}
        border={"1px solid #344054"}
        marginX={"auto"}
        marginBottom={1.5}
      ></Box> */}
      <div>
        <Typography fontWeight={600} fontSize={18} color={"#344054"}>
          {cuisinesText}
        </Typography>
      </div>
    </Box>
  );
};

export default FoodType;
