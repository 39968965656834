import React, { useState } from "react";
import amexKrowd from "../assets/landing/amex-krowd.png";
import headerDt from "../assets/landing/header-dt.jpg";
import headerM from "../assets/landing/header-m.jpg";
import icon1 from "../assets/landing/icon-01.png";
import icon2 from "../assets/landing/icon-02.png";
import icon3 from "../assets/landing/icon-03.png";
import icon4 from "../assets/landing/icon-04.png";
import icon5 from "../assets/landing/icon-05.png";
import icon6 from "../assets/landing/icon-06.png";
import payment from "../assets/landing/payment.jpg";
import footerImage from "../assets/landing/footer-image.png";
import qr2 from "../assets/landing/qr-2.png";
import number1 from "../assets/landing/number-1.png";
import number2 from "../assets/landing/number-2.png";
import number3 from "../assets/landing/number-3.png";
import menu from "../assets/landing/menu.png";
import talkBubble from "../assets/talk-bubble.png";
import simplePricing from "../assets/landing/payment-terms.png";
import datesWeb from "../assets/landing/dates-dt.png";
import datesMobile from "../assets/landing/dates-calendar3.jpg";
import { useNavigate } from "react-router-dom";
import "../styles/mb-style.css";

const LandingPage = () => {
  const [showNavMobile, setShowNavMobile] = useState(false);
  const [show, setShow] = useState(false);
  const route = useNavigate();
  function toggleDrawer() {
    setShowNavMobile((prev) => !prev);
  }
  return (
    <>
      <a href="https://calendly.com/stacey-krowd/30min" target="_blank">
        <div
          id="talk-bubble"
          style={{
            position: "fixed",
            bottom: 40,
            right: 0,
            width: "100px",
            margin: "20px",
          }}>
          <img src={talkBubble} />
        </div>
      </a>
      <div className="position-fixed bottom-0 end-0 mb-3 me-3">
        <a href="#how-it-works" className="mb-btt">
          BACK TO TOP
        </a>
      </div>
      <nav
        style={{ backgroundColor: "#fff !important" }}
        className="navbar navbar-expand-md  fixed-top bg-light mb-nav nav-shadow">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src={amexKrowd} width={"250px"} className="mb-logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              toggleDrawer();
            }}>
            <img src={menu} width={"10em"} />
          </button>
          {showNavMobile ? (
            <div className="drawer navbar-collapse" id="navbarCollapse">
              <ul
                style={{
                  //backgroundColor: "red",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
                className="navbar-nav me-auto mb-2 mb-md-0">
                <li className="nav-item">
                  <a
                    style={{
                      color: "#006fcf",
                      fontSize: "16px",
                      fontFamily: "BentonSans, helvetica, arial, sans-serif",
                    }}
                    className="nav-link active"
                    aria-current="page"
                    href="#how-it-works">
                    HOW IT WORKS
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#benefits"
                    style={{
                      color: "#006fcf",
                      fontSize: "16px",
                      fontFamily: "BentonSans, helvetica, arial, sans-serif",
                    }}>
                    BENEFITS
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    style={{
                      color: "#006fcf",
                      fontSize: "16px",
                      fontFamily: "BentonSans, helvetica, arial, sans-serif",
                    }}
                    className="nav-link"
                    href="#pricing">
                    PRICING
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    style={{
                      color: "#006fcf",
                      fontSize: "16px",
                      fontFamily: "BentonSans, helvetica, arial, sans-serif",
                    }}
                    className="nav-link"
                    href="#getting-started">
                    GETTING STARTED
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav me-auto mb-2 mb-md-0">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#how-it-works">
                      HOW IT WORKS
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#benefits">
                      BENEFITS
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#pricing">
                      PRICING
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#getting-started">
                      GETTING STARTED
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </nav>
      <div id="how-it-works" className="col-12 mb-para m-0">
        <img
          style={{ marginTop: "64px" }}
          src={headerDt}
          width={"100%"}
          className="desktopImage"
        />
        <img src={headerM} className="mobileImage" />
      </div>
      {/* <div>  Try our new Marketing platform </div> */}
      <div>
        <div
          style={{
            // border: "2px solid red",
            width: "auto",
            padding: "5vw 10vw",
            backgroundColor: "#04245a",
            color: "#fff",
            fontSize: "18px",
            fontFamily: "BentonSans, helvetica, arial, sans-serif",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="col-12 col-md-8 mb-para">
          <p
            style={{
              fontSize: "26px",
              fontWeight: "500",
            }}
            className="mb-sub">
            Try our new marketing platform.
          </p>

          <p style={{ maxWidth: "600px", textAlign: "center" }}>
            The marketing platform allows restaurants to target high-spending
            American Express<sup>&reg;</sup> Cardmembers with a 10% cashback
            Offer at restaurants across the country – and your business could
            join them.
          </p>

          <p style={{ maxWidth: "600px", textAlign: "center" }}>
            With a seamless sign-up process, no integration or staff training
            required – acquiring new American Express Cardmembers couldn’t be
            easier.
          </p>
          <p className="pt-40">
            <a href="/signup" className="mb_btn">
              Sign up
            </a>
          </p>
        </div>
      </div>

      <div id="benefits" className="container-fluid">
        <div
          style={{
            marginTop: "32px",
          }}
          className="container text-center">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 mb-para">
              <p
                style={{
                  color: "#006fcf",
                  fontSize: "26px",
                  fontFamily: "BentonSans, helvetica, arial, sans-serif",
                  marginTop: "16px",

                  fontWeight: "500",
                }}
                className="mb-sub mb-blue">
                How does it help my business?
              </p>
            </div>
          </div>

          <div
            style={{ marginTop: "16px" }}
            className="row justify-content-center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
              className="col-12 col-md-4 mb-para-20">
              <img src={icon1} width={"80px"} className="mb-icon" />

              <p className="mb-dk-blue">
                Provide a <b>10% cashback Offer</b> to enrolled Amex
                Cardmembers.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
              className="col-12 col-md-4 mb-para-20">
              <img src={icon2} width={"80px"} className="mb-icon" />
              <p className="mb-dk-blue">
                Drive footfall and{" "}
                <b>
                  fill empty seats with higher paying customers
                </b>
                .
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
              className="col-12 col-md-4 mb-para-20">
              <img src={icon3} width={"80px"} className="mb-icon" />
              <p className="mb-dk-blue">
                American Express promotes businesses that sign up to{" "}
                <b>over 1 million Cardmembers</b>.
              </p>
            </div>
          </div>

          <div
            style={{ marginTop: "24px" }}
            className="row justify-content-center">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
              className="col-12 col-md-4 mb-para-20">
              <img src={icon4} width={"80px"} className="mb-icon" />
              <p className="mb-dk-blue">
                Our partner, Krowd, uses <b>AI tech</b> to recommend restaurants
                to Cardmembers thereby boosting visits to participating
                businesses.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
              className="col-12 col-md-4 mb-para-20">
              <img src={icon5} width={"80px"} className="mb-icon" />
              <p className="mb-dk-blue">
                <b>Simple sign-up process</b>, no integration or staff training
                required.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
              className="col-12 col-md-4 mb-para-20">
              <img src={icon6} width={"80px"} className="mb-icon" />
              <p className="mb-dk-blue">
                <b>Track performance</b> on the Krowd marketing dashboard
                allowing you to monitor performance at all times.
              </p>
            </div>
          </div>
          <div
            style={{ marginTop: "24px" }}
            className="row justify-content-center">
            <iframe width="100%" height="500" src={'https://player.videosmart.com/watch/0oW26kfHE0y'}></iframe>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
              className="col-12 col-md-4 mb-para-20">
              <p className="mb-dk-blue">
                <b>Watch our video</b> to learn more about how the Offer has helped other businesses like yours.
              </p>
            </div>
          </div>
          {/* <div>  PRICING CONTAINER </div> */}
          <div id="pricing" className="container-fluid mb-dark-blue p-50 m-50">
            <div className="pricingContainer">
              <div
                style={{
                  flex: 10,
                }}>
                <img src={payment} className="mb-wide mb-payment-image" />
              </div>

              <div
                className="pricingRight"
                // className="col-12 col-md-6 d-flex align-items-center"
              >
                <div

                //className="mb-para-20 mb-wh"
                >
                  <p
                    style={{ fontWeight: "500" }}
                    id="benefits"
                    className="mb-sub mb-blue">
                    Simple pricing
                  </p>

                  <p className="mb-dk-blue">
                    You only pay for the Offer on a fortnightly basis when a
                    Cardmember has “saved the Offer” and completed a transaction
                    at your business – this helps make sure you only pay for
                    cashback that is helping to directly drive business from the
                    platform.
                  </p>
                  <div className="imagePricing">
                    <img src={simplePricing} />
                  </div>
                  <p className="pt-40">
                    <a href="/signup" className="mb_btn">
                      Sign up
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div>  1 2 3 POINTS CONTAINER </div> */}
          <div className="container-fluid">
            <div className="container text-center">
              <div
                style={{
                  margin: "24px 0 24px 0",
                  backgroundColor: "#04245A",
                  color: "#fff",
                  fontSize: "18px",
                  padding: "12px",
                  // borderWidth: 2,
                  // borderColor: "red",
                }}
                className="container-fluid ">
                <div className="row justify-content-center ">
                  <div className="col-12 col-md-10 mb-para m-0">
                    <div className="container  mb-dark-blue">
                      <div className="row justify-content-center ">
                        <div className="row justify-content-center">
                          <div className="col-12 mb-para">
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "24px",

                                marginBottom: "24px",
                              }}
                              className="mb-sub mb-left">
                              What happens once I’ve signed up {` `}?
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-center ">
                        <div className="numberedSection">
                          <img src={number1} width={"80px"} height={"80px"} />

                          <p className="pointPara">
                            Every other week Krowd will collect the 10%
                            cashback, plus a 5% admin fee for successful
                            transactions from your Account via direct debit
                            until the Offer ends (or you choose to cancel).
                          </p>
                        </div>

                        <div className="numberedSection">
                          <img src={number2} width={"80px"} height={"80px"} />

                          <p className="pointPara">
                            You will be added to the online dining map,
                            accessible to over 1 million Cardmembers actively
                            promoted by American Express to drive more business.
                          </p>
                        </div>

                        <div className="numberedSection">
                          <img src={number3} width={"80px"} height={"80px"} />

                          <p className="pointPara">
                            <a
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginLeft: "3px",
                              }}
                              onClick={() => {
                                route("/signup");
                              }}>
                              Sign up here
                            </a>
                            {`  `} and simply fill in some business and payment
                            details and that’s it – we’ll do the rest.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="getting-started"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}>
                <div className="col-12 col-md-8 mb-para upcomingOffers">
                  <p
                    style={{
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                    className="mb-sub mb-blue mb-left">
                    Upcoming Offer periods{" "}
                    <div style={{ fontSize: 14, fontColor: "#000 !important" }}>
                      The upcoming Offer periods are subject to change.
                    </div>
                  </p>

                  <p
                    className="mb-blue mb-left"
                    style={{ textAlign: "center" }}>
                    Sign up to participate in this new marketing platform for
                    any of the periods below. Please note that some Offer
                    periods will have already started, so register your interest
                    based on the dates that best suit your business needs.
                  </p>
                  <p
                    className="mb-blue mb-left"
                    style={{ textAlign: "center" }}>
                    We ask our Cardmembers to re-enrol into each Offer period to ensure American Express is truly driving incremental customers through your door.
                  </p>

                  <p
                    className="mb-blue mb-left"
                    style={{ textAlign: "center" }}>
                    <b>Let's get started</b>
                  </p>

                  <p className="pt-20 mb-left" style={{ textAlign: "center" }}>
                    <a href="/signup" className="mb_btn">
                      Sign up
                    </a>
                  </p>
                </div>
                <div className="datesWebContainer">
                  <img src={datesWeb} width={"100%"} />
                </div>
                <div className="datesMobileContainer">
                  <img src={datesMobile} width={"100%"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Featured and Footer */}
      <div style={{ width: "90%", marginLeft: "5%" }}>
        <div
          style={{
            margin: "24px 0 0 0",
            backgroundColor: "#04245A",
            color: "#fff",
            fontSize: "18px",
            padding: "18px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "50px 20px",
          }}
          className="container-fluid ">
          <p
            style={{
              fontSize: 24,
              fontWeight: "500",
            }}>
            Want to see where you'll be featured?
          </p>

          <p
            style={{
              width: "60%",
              textAlign: "center",
            }}>
            Once you've enrolled in Amex Dining your restraurant will be added
            to the eDirectory, please click the button below to explore.
          </p>
          <p className="pt-20 mb-left">
            <div
              onClick={() => {
                route("/home");
              }}
              className="mb_btn">
              View the eDirectory
            </div>
          </p>
        </div>
        <img src={footerImage} width={"100%"} />
        <div
          style={{
            minHeight: "120px",
          }}>
          <p
            style={{
              fontWeight: "600",
              margin: "10px 0",
              textAlign: "left",
              color: "#75787b",
            }}>
            Krowd Limited Terms and Conditions
          </p>
          <div
            onClick={() => {
              setShow((prev) => !prev);
            }}
            className="readTermsButton">
            Read full terms
          </div>
        </div>
        <div
          style={{
            display: show ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "50px",
            color: "#75787b",
          }}>
          <div
            style={{
              width: "80vw",
              paddingLeft: "2vw",
              paddingRight: "2vw",
              backgroundColor: "#fff",
              //maxHeight: "100vh",
            }}>
            <div className="col-12 m-t-15">
              These terms and conditions govern the access to, and use of, the
              marketplace offered by Krowd Limited at www.krowdit.com by a
              Reward Partner.
            </div>

            <div className="col-12 m-t-10">
              Krowd Limited <b>(“Krowd”)</b> is a company incorporated in
              England and Wales with registered number 10027920 whose registered
              address is at Kemp House, 152-160 City Road, London, England, EC1V
              2NX.
            </div>

            <div className="col-12 m-t-10">
              <b>1. Definitions</b>
            </div>

            <div className="col-12 m-t-10">
              The following words shall have the following meanings within these
              terms and conditions.
            </div>

            <div className="col-12 m-t-10">
              <b>Analytics Fee</b> means the fee of 2% of gross merchant value
              for the provision of Marketplace access and analytics tools
              provided by Krowd to the Reward Partner. This is included in the
              Success Fee.
            </div>

            <div className="col-12 m-t-10">
              <b>Cashback</b> means the relevant cashback offered to a Customer
              in an Offer.
            </div>

            <div className="col-12 m-t-10">
              <b>Customer</b> means the customers of the Publisher, to whom the
              Publisher has provided Offers to.
            </div>

            <div className="col-12 m-t-10">
              <b>Success Fee</b> means the fee to be paid by the Reward Partner
              to Krowd for successful Offer Redemptions as submitted by the
              Reward Partner as part of any Offer submission, which is inclusive
              of the Analytics Fee.
            </div>

            <div className="col-12 m-t-10">
              <b>Marketplace</b> means the Krowd customer acquisition
              marketplace hosted at www.krowdit.com.
            </div>

            <div className="col-12 m-t-10">
              <b>Offer </b> means the cashback Offers uploaded by Reward
              Partners onto the Marketplace for the purchase of certain goods or
              services from the Reward Partner.
            </div>

            <div className="col-12 m-t-10">
              <b>Offer Redemptions</b> means the purchase of goods and services
              by a Customer that is subject to an Offer.
            </div>

            <div className="col-12 m-t-10">
              <b>Publisher</b> means participating banks, financial partners,
              airlines, employee benefit platforms and other partners of Krowd
              who provide Offers to their end customers and users.
            </div>

            <div className="col-12 m-t-10">
              <b>Reward Partner</b> means a merchant who uploads an Offer onto
              the Marketplace.
            </div>

            <div className="col-12 m-t-10">
              <b>2. Making an Offer Available</b>
            </div>

            <div className="col-12 m-t-10">
              2.1) The Reward Partner shall make an Offer available through the
              Marketplace by completing the Offer submission on the Marketplace
              which shall include such information as is required for Krowd to
              host the Offer on the Marketplace, including target Customer
              group, qualifying Offer criteria, total available Offer budget as
              well as desired level of Cashback and the Success Fee as well as
              upload Reward Partner’s logo and any other images or content as
              may be reasonably required to enable the hosting of the Offer.
            </div>

            <div className="col-12 m-t-10">
              2.2) The value of the Offer and the Success Fee will in all
              circumstances be agreed between Krowd and the Reward Partner prior
              to making the Offer available – either through the Marketplace or
              directly in writing.
            </div>

            <div className="col-12 m-t-10">
              2.3) By making an Offer submission, the Reward Partner undertakes
              to honour such Offer for all and any purchases made by Customers
              who make an Offer Redemption.
            </div>

            <div className="col-12 m-t-10">
              2.4) Reward Partner Offer submissions may be amended by the Reward
              Partner at any point up until 30 calendar days before the end of
              the month prior to the month in which the Offer is due to be run
              (the <b>“Amendment Period”</b>). Past this point, all amendments
              shall be at the discretion of Krowd.
            </div>

            <div className="col-12 m-t-10">
              2.5) Krowd's campaigns and the Amex Dining program have different
              operational cycles, with Reward partners automatically enrolled
              for subsequent cycles in both programs. To opt out of any cycle,
              partners must provide a 30-day notice prior to the end of their
              current cycle.
            </div>

            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.5.1. Scenario 1: Krowd's
              Monthly Campaign
            </div>
            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Krowd conducts its campaigns
              on a monthly rotation. For instance, if a Reward partner is active
              in the June campaign, running from June 1st to June 30th, they are
              set to be auto-enrolled in the subsequent July campaign, covering
              July 1st to July 31st. To decline participation in the July cycle,
              the partner needs to inform Krowd by May 31st, ensuring a 30-day
              notification ahead of the start of the next campaign.
            </div>
            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2.5.2. Scenario 2: Amex
              Dining's 3-Month Cycle
            </div>
            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The Amex Dining program
              operates in 3-month intervals. If the current cycle of a Reward
              partner spans from January 1st to March 31st, and the next
              campaign interval is scheduled for April 1st to June 30th.
              Partners wishing to opt out of the April to June campaign must
              provide their notice to Krowd by March 1st, again adhering to the
              30-day notification requirement before the onset of the subsequent
              cycle.
            </div>

            <div className="col-12 m-t-10">
              2.6) It's recommended to have a legal professional review this
              clause to ensure its suitability and clarity within the context of
              your contract.
            </div>

            <div className="col-12 m-t-10">
              2.7) Following successful completion of an Offer submission, Krowd
              shall confirm to the Reward Partner acceptance of the Offer along
              with relevant details, Krowd reserves the right to not approve any
              Offer or ask for reasonable amendments prior to such approval.
            </div>

            <div className="col-12 m-t-10">
              2.8) Krowd shall make the Offer available to a portfolio of
              Publishers, should the Reward Partner not want the Offer to be
              made with any specific Publishers then it must notify Krowd via{" "}
              <a className="anchor-tag" href="mail:info@krowdit.com">
                info@krowdit.com
              </a>{" "}
              within the Amendment Period otherwise Krowd shall make the Offer
              available to all Publishers it deems fit.
            </div>

            <div className="col-12 m-t-10">
              2.9) Krowd may upload the Merchant IDs to the Marketplace up to 30
              days before the Offer goes live in order to review transaction
              analytics.
            </div>

            <div className="col-12 m-t-10">
              <b>3. Offer Redemption</b>
            </div>

            <div className="col-12 m-t-10">
              3.1) Where the goods being offered by the Reward Partner have a
              return period, Krowd shall invoice the Success Fee on completion
              the applicable return period (up to a maximum of 30 days).
            </div>

            <div className="col-12 m-t-10">
              <b>4. Data Protection</b>
            </div>

            <div className="col-12 m-t-10">
              4.1) Krowd does not store or process personal data from Reward
              Partners and will never ask the Reward Partner to share such data
              with Krowd. If the Offer Redemption requires a unique code, the
              Reward Partner agrees to share the full list or redeemed codes
              only. Krowd assumes no liability for any customer data shared
              accidentally or intentionally with Krowd.
            </div>

            <div className="col-12 m-t-10">
              4.2) The main purposes for which we use non-personal information
              are:
            </div>

            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.1. Management and
              administrative purposes of your purchases through Krowd
            </div>

            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.2. Providing services
              tailored to you.
            </div>
            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.3. Analysis and market
              research
            </div>
            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.4. Updates and service
              communications
            </div>
            <div className="col-12 m-t-10">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4.2.5. Transaction monitoring
            </div>

            <div className="col-12 m-t-10">
              <b>5. Invoicing and Payment Terms</b>
            </div>

            <div className="col-12 m-t-10">
              5.1) The Reward Partner shall pay Krowd the Success Fee and
              Cashback for all Offer Redemptions on a weekly basis. Krowd shall
              invoice the Reward Partner on a weekly basis through Direct Debit
              as provided via the GoCardless Direct Debit on the Marketplace.
              The invoice shall be payable within 5 days. Late payments shall
              accrue interest at a rate of 5% above the Bank of England base
              rate from time to time.
            </div>
            <div className="col-12 m-t-10">
              5.2) <b>Deposit and Refund</b>
            </div>

            <div className="col-12 m-t-10">
              The merchant could be required to pay a deposit of £99 via direct
              debit to cover any cashback that may be owed to the customers as
              part of the campaign. Any remaining funds from the deposit shall
              be returned to the merchant within 8 working days after the end of
              the campaign. The deposit shall be refunded to the merchant in the
              same method of payment that was used to pay the deposit. The
              merchant shall be responsible for any fees or charges associated
              with the direct debit transaction, including but not limited to
              bank fees or insufficient funds fees. In the event that the
              merchant breaches any terms of this agreement, the deposit may be
              used to cover any damages or losses incurred by the Krowd.
            </div>
            <div className="col-12 m-t-10">
              5.3) Krowd undertakes to transfer the Cashback to the relevant
              Publisher for payment to its Customers, pursuant to its relevant
              terms and conditions with that Publisher.
            </div>
            <div className="col-12 m-t-10">
              5.4) Should Krowd receive any data outside of the invoicing period
              that relates to Offer Redemptions, Krowd shall be entitled to
              invoice for such Offer Redemptions in the next invoice.
            </div>

            <div className="col-12 m-t-10">
              <b>6. Enrolment with Publishers</b>
            </div>
            <div className="col-12 m-t-10">
              6.1) Krowd will automatically promote your brand to a portfolio of
              exclusive Publishers that have access to customers. If you do not
              want to be automatically enrolled, and consequently want to select
              a Publisher of choice then the brand will need to opt-out.
            </div>
            <div className="col-12 m-t-10">
              6.2) Such requests should be communicated in writing to Krowd via{" "}
              <a className="anchor-tag" href="mail:info@krowdit.com">
                info@krowdit.com
              </a>
            </div>

            <div className="col-12 m-t-10">
              <b>7. Removing an Offer</b>
            </div>

            <div className="col-12 m-t-10">
              7.1) The Offer shall remain on the Marketplace for the duration of
              the Offer, as selected by the Reward Partner during the Offer
              submission. The Reward Partner may request for an Offer to end
              early by providing written notice to Krowd via{" "}
              <a className="anchor-tag" href="mail:info@krowdit.com">
                info@krowdit.com
              </a>
              . Krowd shall, upon its discretion, remove the Offer by the end of
              the month following the month in which such notice is given.
              Notwithstanding the foregoing, Krowd reserves the right to remove
              an Offer at any time in its complete discretion.
            </div>

            <div className="col-12 m-t-10">
              <b>8. Krowd’s role as an intermediary </b>
            </div>
            <div className="col-12 m-t-10">
              8.1) Krowd’s role is as an intermediary and limited to providing
              the Offer to the Publisher who then provides such Offer direct to
              its Customers. Any Offer Redemption is a separate contract between
              the Reward Partner and the Customer and the Reward Partner is
              responsible for the fulfilment of such contract, including abiding
              by all relevant laws in relation to the provision of the relevant
              goods or services and fulfilment of such contract. The Reward
              Partner hereby indemnifies and agrees to keep Krowd and its
              Publishers indemnified against any loss or claim it may suffer as
              a result of the Reward Partner’s failure to successfully perform
              such contract (including not honouring the Offer), including any
              loss as a result of any marketing material provided by the Reward
              Partner in any materials provided by the Reward Partner to Krowd
              as part of the Offer submission. The Reward Partner further
              warrants to Krowd that is has sufficient legal rights to provide
              all the marketing materials, logos, images etc it provides to
              Krowd for the Offer submission.
            </div>
            <div className="col-12 m-t-10">
              8.2) Krowd is not responsible for Publishers subsequent marketing
              of the Offer and makes no representations or warranties about the
              extent to which an Offer is marketed, including whether it is
              marketed at all. Krowd is not responsible for any failure by a
              Publisher or itself to make an Offer available.
            </div>

            <div className="col-12 m-t-10">
              <b>9. License</b>
            </div>

            <div className="col-12 m-t-10">
              9.1) The Reward Partner grants Krowd and Publishers a
              royalty-free, non-exclusive license to use the Reward Partner’s
              trade marks and other marketing material provided as part of the
              Offer submission by the Reward Partner through the Marketplace for
              the purposes of making the Offer available and to be used in
              Krowd’s marketing materials, including Krowd’s website.
            </div>

            <div className="col-12 m-t-10">
              <b>10. Miscellaneous</b>
            </div>
            <div className="col-12 m-t-10">
              10.1) The Reward Partner agrees not to work directly or indirectly
              with any Publisher introduced by Krowd during and for 6 months
              after the campaign has completed.
            </div>
            <div className="col-12 m-t-10">
              10.2) To the fullest extent permitted by law, Krowd’s liability
              under this agreement is limited to aggregate Success Fees received
              from the Reward Partner in the preceding 12-month period.
            </div>
            <div className="col-12 m-t-10">
              10.3) These terms come into effect on an Offer submission and
              remain in effect until that Offer has been completed.
            </div>
            <div className="col-12 m-t-10">
              10.4) Both parties shall keep the terms of the agreement and all
              and any underlying data shared or processed as part of the
              agreement confidential, save where required to disclose by law.
            </div>
            <div className="col-12 m-t-10">
              10.5) All notices may be given, in the case of Krowd to the
              address at the top of these terms and conditions and by email to{" "}
              <a className="anchor-tag" href="mail:info@krowdit.com">
                info@krowdit.com
              </a>
              , in the case of the Reward Partner, to the address and email
              provided as part of the Offer submission.
            </div>
            <div className="col-12 m-t-10">
              10.6) This agreement constitutes the entire agreement between the
              parties in relation to the subject matter it contains.
            </div>

            <div className="col-12 m-t-10">
              <b>11. Governing law</b>
            </div>
            <div className="col-12 m-t-10">
              11.1) These terms and conditions and all matters, including
              non-contractual obligations, arising hereunder shall be governed
              by the laws of England and Wales and each party irrevocably
              submits to the exclusive jurisdiction of the courts of England and
              Wales.
            </div>
          </div>
          {/* <div
            style={{
              backgroundColor: "#fff",
              width: "80vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              //marginRight: "4vw",
            }}>
            <button
              style={{
                //color: "#fff",
                fontSize: "14px",
                //backgroundColor: "#026FCF",
                padding: "10px 16px",
                borderRadius: "6px",
                margin: "10px",
              }}
              onClick={() => {
                setShow(false);
              }}>
              Cancel
            </button>{" "}
            <button
              style={{
                color: "#fff",
                fontSize: "14px",
                backgroundColor: "#026FCF",
                padding: "10px 16px",
                borderRadius: "6px",
              }}
              //onClick={postFormData}
            >
              Accept
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default LandingPage;
