import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { defaultTheme } from "./theme/defaultTheme";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://623d3df046cc4e219ed7199d33abbd6d@o4505192948629504.ingest.sentry.io/4505192961802240",
  autoSessionTracking: true,
  environment: "development",
  integrations: [new Sentry.BrowserTracing()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={defaultTheme}>
    <App />
  </ThemeProvider>
);

reportWebVitals();
