import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import FooterMenu from "../components/FooterMenu";
import AuthHeader from "../components/headers/AuthHeader";
import { getBankName } from "../utils";
import Header from "../components/homepage/Header";
import BottomMenu from "../components/homepage/BottomMenu";
import Footer from "../components/homepage/Footer";

const TermsCondition = () => {
  return (
    <>
      <Helmet>
        <title>
          {getBankName() === "travx" ? "Travx" : "Amex Dining Offer"}
        </title>
      </Helmet>
      
      <Box className={getBankName() === "travx" ? "header" : ""}>
      {getBankName() === "travx" ? <AuthHeader title={"All Brands"} search={true} /> : <Header />}
      </Box>
      <Box className="viewFooter">
      <Container maxWidth="xl">
        <Typography
          paddingTop={getBankName() === "travx" ? "6rem" : "3rem" }
          as="h2"
          variant="h3"
          className="brandombold"
        >
          Terms & Conditions:
        </Typography>
        <ul style={{listStyle: "disc"}} className="terms_ul">
          <li>
            These Offer Terms (including the offer description above) set out
            the key terms of our Dining offer. Please make sure you read these
            together with our{" "}
            <a href="https://www.americanexpress.com/uk/network/amex-offers-terms.html">
              General Offer Terms
            </a>
            , which contain important additional terms, including information
            about when statement credits or points can be reversed or lost.
          </li>
          <li>
            Statement credits will be awarded for eligible transactions using
            your Card during the offer period, as explained above and within
            these Offer Terms and General Terms. A statement credit should
            appear on your Card Account statement within 30 days from the date
            you made an eligible transaction but may take up to 90 days from the
            Offer End Date
          </li>
          <li>
            Offer only applies to spend settled in Great British Pound (GBP) on
            the Card to which the offer is saved. By saving the Offer to your
            Card, you agree to all our Offer Terms.
          </li>
          <li>
            Spend must be billed to your Card account by 19/11/2023,to be eligible
            for this offer. If participating merchants do not charge your Card
            during this period, you may not be eligible for this offer
          </li>
          <li>
            This Offer is only valid for transactions made on-site at
            restaurants and select online merchants that are participating in
            our Dining Offer. To search for participating Dining locations
            please visit the{" "}
            <a href="http://www.amexdining.com/">e-directory</a> . Eligible
            locations will be highlighted on this page. Restaurants
            participating in the Dining offer may change and the{" "}
            <a href="http://www.amexdining.com/">e-directory</a> may vary or
            contain errors so please contact restaurants directly to check they
            are open and accept American Express. We reserve the right to change
            or cancel this Offer at any time.
          </li>
          <li>Offer valid at UK locations only.</li>
          <li>
            Cancellations and no show charges are excluded from this offer
          </li>
          <li>
            Offer excludes purchases made with third party delivery or take away
            services. Delivery orders placed via Deliveroo, UberEats and JustEat
            are excluded. Click and Collect is excluded.
          </li>
          <li>
            Offer excludes purchases of gift cards and vouchers. Available in
            participating restaurant(s) or select online locations included on
            the <a href="http://www.amexdining.com/">e-directory</a>.
          </li>
          <li>
            This offer only applies to purchases on the Card to which the offer
            is saved (including where the Card has been added to a mobile
            wallet). If we send you a replacement Card following any fraud [or
            fraudulent activity] on your Card, and if the offer is still
            available, you may need to save the offer again to your replacement
            Card for you to be enrolled.
          </li>
          <li>
            Purchases made through “payment facilitators” are not eligible and
            will not count towards your spend for the purposes of the offer. A
            payment facilitator is an intermediary that accepts payments on
            behalf of the seller of the goods/services. Payment facilitators can
            include some online payment platforms/ marketplaces, and entities
            that provide certain card acceptance products (such as mobile card
            readers) to sellers of goods/services; transactions that are
            subsequently cancelled or refunded will not count towards your spend
            for the purposes of the offer.
          </li>
          <li>
            By enrolling and making a qualifying purchase, you acknowledge that
            to enable you to redeem the offer, American Express may share
            certain transaction data and the first digits of your Account number
            with the merchant to reconcile and assess the offer
          </li>
          <li>
            For more information about how we share your information with the
            merchant, please review our{" "}
            <a href="https://www.americanexpress.com/uk/legal/online-privacy-statement.html">
              Cardmember Privacy Statement
            </a>
            . We will use your personal information such as name, email address,
            Account number and transaction information to manage your
            participation in the offer and to track your spend. Please see our{" "}
            <a href="https://www.americanexpress.com/uk/legal/online-privacy-statement.html">
              Cardmember Privacy Statement
            </a>{" "}
            for details of how we use your personal information.
          </li>
        </ul>
      </Container>
      <Footer />
      </Box>
      <BottomMenu />
    </>
  );
};

export default TermsCondition;
