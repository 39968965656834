// ExternalLink.js
import React, { useEffect } from "react";

const ExternalLink = () => {
  useEffect(() => {
    window.location.href = "https://cam-beta.krowdit.com/amexdiningsignup"; // Set the external URL here
  }, []);

  return <div>Redirecting...</div>; // You can render a loading message here
};

export default ExternalLink;
