import { ToastMessage } from "../components/ToastMessage";

export const GeoLocationService = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } else {
      ToastMessage("Browser not support Location Services");
    }
  });
};
