import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Button, Stack, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { Link } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { withCommas } from "../../services/utility";
import { getUserInfo } from "../../auth";
import { beforeGetDirectionApi, bookNow } from "../../services/HomeApi";
import { Mixpanel } from "../../services/MixPanel";
import FeedBack from "../../components/FeedBack";
import { wrap } from "@sentry/react";
import "./details.css";
import Logo from "../../assets/homepage-logo.svg";
import { ToastMessage } from "../ToastMessage";
import axios from "axios";
const DetailsBox = ({ brandName }) => {
  const params = useParams();
  const route = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [termsCheckBox, setTermsCheckBox] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const couponPrices = [5, 10, 15, 25, 50, 75, 100, 125, 150, 200, 250];
  useEffect(() => {
    const handleResize = () => {
      // Set the threshold width to consider the screen as mobile (adjust as needed)
      const mobileThreshold = 768;
      setIsMobile(window.innerWidth < mobileThreshold);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const postFormData = async () => {
    const payload = {
      mailTo: email,
      mailBody:
        "Thanks for purchasing a giftcard from Amexgift cards. Your voucher number is 123 and is valid for one year",
    };
    try {
      const response = await axios.post(
        "https://offersapi-test.krowdit.com/api/auth/sendEmailForGiftcard",
        payload
      );
      console.log("API response:", JSON.stringify(response));
      if (response.data.respCode === 200) {
        console.log("success");
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const handlePayment = () => {
    if (termsCheckBox) {
      console.log("Checkbox is checked");
      sessionStorage.setItem("couponValue", selectedCoupon);
      postFormData();
      route(`/paymentConfirmation/${brandName}`);
    } else {
      console.log("Checkbox is not checked");
      ToastMessage("Review Terms of service before finalising your purchase");
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <Box
        borderRadius={"12px"}
        border={{ xs: "none", sm: "1px solid #E5E7EB" }}
        bgcolor={"#fff"}
        boxShadow={{
          xs: "none",
          sm: "0px 10px 10px 0px rgba(31, 41, 55, 0.04), 0px 20px 25px 0px rgba(31, 41, 55, 0.10)",
        }}
        padding={{ xs: 0, sm: 3 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          {pageNo !== 3 && (
            <Box
              bgcolor={"#F2F4F7"}
              borderRadius={"16px"}
              paddingX={"10px"}
              paddingY={"2px"}
              fontWeight={500}
              fontSize={"14px"}
              color={"#344054"}>
              10% Cashback
            </Box>
          )}
        </Stack>
        {pageNo !== 3 && (
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={0.5}>
              <Typography
                fontWeight={600}
                color={"#101828"}
                letterSpacing={"-0.72px"}
                fontSize={32}
                className="detailsBrandName"
                sx={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {brandName}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography
                fontSize={20}
                fontWeight={700}
                color={"grey"}
                className="detailsBranchName">
                Gift cards are redeemable at {brandName}.com and at selected
                {` ${brandName}`}-owned retail locations.
              </Typography>
              <Box
                width={2}
                height={2}
                bgcolor={"#344054"}
                borderRadius={"50%"}
                marginX={0.5}></Box>
            </Stack>
          </Box>
        )}
        {pageNo === 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid grey",
              borderRadius: 10,
              padding: 20,
              marginTop: 10,
            }}>
            <Typography fontSize={18} fontWeight={700} color={"black"}>
              Choose
            </Typography>
            <Box>
              <Typography fontSize={16} fontWeight={600} color={"grey"}>
                Who are you gifting?
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: 20,
                  margin: "10px 0",
                }}>
                <div
                  style={{
                    height: 40,
                    width: "45%",
                    backgroundColor:
                      selectedUser === "Myself" ? "purple" : "white",
                    border: "1px solid purple",
                    color: selectedUser === "Myself" ? "white" : "purple",
                    borderRadius: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedUser("Myself");
                  }}>
                  Myself
                </div>
                <div
                  style={{
                    height: 40,
                    width: "45%",
                    backgroundColor:
                      selectedUser === "Others" ? "purple" : "white",
                    border: "1px solid purple",
                    color: selectedUser === "Others" ? "white" : "purple",
                    cursor: "pointer",
                    borderRadius: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setSelectedUser("Others");
                  }}>
                  Someone Else
                </div>
              </div>
            </Box>
            <Box>
              <Typography fontSize={16} fontWeight={600} color={"grey"}>
                {`What's your gift value? (GBP)`}
              </Typography>
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  // border: "1px solid red",
                  flexWrap: "wrap",
                  gap: 12,
                }}>
                {couponPrices.map((item) => {
                  return (
                    <div
                      style={{
                        height: 40,
                        width: 100,
                        backgroundColor:
                          selectedCoupon === item ? "purple" : "white",
                        border: "1px solid purple",
                        color: selectedCoupon === item ? "white" : "purple",
                        borderRadius: 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedCoupon(item);
                      }}>
                      {`£ ${item}`}
                    </div>
                  );
                })}
              </div>
            </Box>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  height: 40,
                  width: "80%",
                  marginTop: 20,
                  backgroundColor: "blue",
                  flex: 1,
                  color: "white",
                  borderRadius: 6,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (selectedUser == "") {
                    console.log("first");
                    ToastMessage("Please select Myself or Someone Else");
                  } else {
                    if (selectedCoupon == "") {
                      ToastMessage("Please select a gift value");
                    } else {
                      console.log("okkk");
                      setPageNo(pageNo + 1);
                    }
                    // setSelectedUser("Myself");
                  }
                }}>
                Save and Continue
              </div>
            </div>
          </div>
        )}
        {pageNo === 2 && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
                border: "1px solid grey",
                borderRadius: 10,
                padding: 20,
                marginTop: 10,
              }}>
              <Typography fontSize={18} fontWeight={700} color={"black"}>
                Choose
              </Typography>
              <Typography fontSize={14} fontWeight={500} color={"grey"}>
                {`£${selectedCoupon} , for ${selectedUser}`}
              </Typography>
              <div
                onClick={() => {
                  setPageNo(pageNo - 1);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}>
                <Typography
                  fontSize={14}
                  fontWeight={500}
                  color={"blue"}
                  position={"absolute"}
                  right={50}>
                  EDIT
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                border: "1px solid grey",
                borderRadius: 10,
                padding: 20,
                marginTop: 10,
              }}>
              <Typography fontSize={18} fontWeight={700} color={"black"}>
                Send
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 10,
                  //border: "1px solid red",
                  padding: 0,
                  //marginTop: 10,
                }}>
                <Typography fontSize={16} fontWeight={500} color={"black"}>
                  Your Details
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 20,
                  }}>
                  <Typography fontSize={14} fontWeight={500} color={"grey"}>
                    Already Registered ?
                  </Typography>
                  <div
                    style={{
                      height: 35,
                      width: 80,
                      backgroundColor: "white",
                      border: "1px solid black",
                      color: "black",
                      borderRadius: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      fontSize: 14,
                    }}
                    onClick={() => {
                      //setSelectedCoupon(item);
                    }}>
                    Login
                  </div>
                </div>
              </div>
              {/* <div class="input-container">
                <input type="text" id="brand" required />
                <label for="brand">Brand</label>
              </div> */}
              <div
                className="emailNnameContainer"
                style={{ marginTop: 10, padding: 5 }}>
                <input
                  style={{ border: "1px solid grey", marginBottom: 10 }}
                  placeholder="E-mail"
                  onInput={(e) => setEmail(e.target.value)}
                />
                <div style={{ display: "flex", gap: 10 }}>
                  <input
                    style={{ border: "1px solid grey" }}
                    placeholder="First Name"
                    onInput={(e) => setFirstName(e.target.value)}
                  />
                  <input
                    style={{ border: "1px solid grey" }}
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    height: 40,
                    width: "80%",
                    marginTop: 20,
                    backgroundColor: "blue",
                    flex: 1,
                    color: "white",
                    borderRadius: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={
                    () => {
                      if (email == "") {
                        ToastMessage("Please Enter a valid Email");
                      } else {
                        if (firstName == "") {
                          ToastMessage("Please Enter your First Name");
                        } else {
                          setPageNo(pageNo + 1);
                        }
                      }
                    }
                    // setSelectedUser("Myself");
                  }>
                  Save and Continue
                </div>
              </div>
            </div>
          </div>
        )}
        {pageNo === 3 && (
          <Stack>
            <Typography
              fontWeight={600}
              color={"#101828"}
              letterSpacing={"-0.72px"}
              fontSize={24}
              className="detailsBrandName"
              sx={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              Preview and Pay
            </Typography>
            <div
              style={{
                height: 1,
                width: "100%",
                backgroundColor: "#e1e1e1",
                marginBottom: 10,
                marginTop: 10,
              }}></div>
            <Typography
              fontWeight={600}
              color={"#101828"}
              letterSpacing={"-0.72px"}
              fontSize={32}
              className="detailsBrandName"
              sx={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {`${brandName} Gift Card`}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 10,
                marginTop: 10,
              }}>
              <div>
                <Typography fontSize={16} fontWeight={500} color={"black"}>
                  {`For ${selectedUser}`}
                </Typography>
                <Typography
                  fontSize={16}
                  fontWeight={400}
                  color={"grey"}
                  marginTop={0.2}>
                  {email}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}>
                <Typography fontSize={14} fontWeight={500} color={"grey"}>
                  {`£ ${selectedCoupon}`}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: 10,
                marginTop: 5,
              }}>
              <Typography fontSize={16} fontWeight={500} color={"black"}>
                Total
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}>
                <Typography fontSize={14} fontWeight={500} color={"black"}>
                  {`£ ${selectedCoupon}`}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // /justifyContent: "space-between",
                gap: 20,
                alignItems: "center",
                borderRadius: 10,
                //marginTop: 5,
              }}>
              {/* <Typography
                fontSize={36}
                fontWeight={300}
                color={"black"}
                paddingBottom={0.5}>
                +
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 20,
                }}>
                <Typography
                  fontSize={15}
                  fontWeight={500}
                  color={"grey"}
                  paddingTop={0.6}>
                  Add promo code
                </Typography>
              </div> */}
            </div>
            <div
              style={{
                height: 1,
                width: "100%",
                backgroundColor: "#e1e1e1",
                marginBottom: 10,
                marginTop: 10,
              }}></div>
            <div
              style={{
                height: 100,
                width: "100%",
                border: "1px solid blue",
                borderRadius: 10,
                backgroundColor: "#E9FFFF",
                marginTop: 10,
                marginBottom: 10,
                padding: "10px 10px 10px 25px",
              }}>
              <Typography fontSize={15} fontWeight={500} color={"blue"}>
                Were you asked to buy this gift?
              </Typography>
              <Typography
                fontSize={15}
                fontWeight={500}
                color={"grey"}
                marginTop={0.3}>
                No legit business will ever ask you to pay them with a gift
                card. Been asked to do so?{" "}
                <a href="mailto:info@krowdit.com" style={{ color: "blue" }}>
                  {" "}
                  Contact Us
                </a>
              </Typography>
            </div>
            <div
              style={{
                height: 1,
                width: "100%",
                backgroundColor: "#e1e1e1",
                marginBottom: 10,
                marginTop: 10,
              }}></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}>
              <Typography
                fontWeight={600}
                color={"#101828"}
                letterSpacing={"0.3px"}
                fontSize={18}
                sx={{
                  width: "auto",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                Payment
              </Typography>
              <img
                src={Logo}
                alt=""
                style={{ height: "40px", width: "40px" }}
              />
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                //justifyContent: "center",
                alignItems: "center",
                gap: 10,
              }}>
              <div>
                <input type="checkbox" width={"auto"} id="termsCheckBox" onClick={(e)=> setTermsCheckBox(e.target.checked)}/>
              </div>
              <Typography
                fontSize={15}
                fontWeight={500}
                color={"grey"}
                marginTop={0.3}
                //border={"2px solid red"}
              >
                By placing an order, you agree to American Express's{" "}
                <span style={{ color: "blue" }}>
                  {" "}
                  Privacy Policy , Terms of Service and Terms of Sale.
                </span>
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                //justifyContent: "center",
                alignItems: "center",
                gap: 10,
              }}>
              <div>
                <input type="checkbox" width={"auto"} />
              </div>
              <Typography
                fontSize={15}
                fontWeight={500}
                color={"grey"}
                marginTop={0.3}
                //border={"2px solid red"}
              >
                Let me know of any American Express special offers.
              </Typography>
            </div>
            <div
              style={{
                height: 1,
                width: "100%",
                backgroundColor: "#e1e1e1",
                marginBottom: 10,
                marginTop: 10,
              }}></div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                id="paymentBtn"
                style={{
                  height: 40,
                  width: "80%",
                  marginTop: 20,
                  backgroundColor: "blue",
                  flex: 1,
                  color: "white",
                  borderRadius: 6,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handlePayment();
                }}>
                Pay for the Gift Card
              </div>
            </div>
          </Stack>
        )}
      </Box>
    </div>
  );
};

export default DetailsBox;
