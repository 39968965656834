import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/homepage/Header";
import GetDiscount from "../../components/homepage/GetDiscount";
import Recommended from "../../components/homepage/NewRecommended";
import RecommendedBlue from "../../components/homepage/RecommendedBlue";
import FoodType from "../../components/homepage/FoodType";
import SaveMoney from "../../components/homepage/SaveMoney";
import Footer from "../../components/homepage/Footer";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import "../../components/homepage/homepage.css";

import Slider from "../../components/newSlider";
import { SwiperSlide } from "swiper/react";
import { getUserInfo } from "../../auth";
import { getOffers } from "../../services/HomeApi";
import ErrorBoundary from "../../ErrorBoundary";
import { en } from "../../appLabels/engLabels";
import BottomMenu from "../../components/homepage/BottomMenu";
import MapCard, { MapCardMobile } from "../../components/homepage/MapCard";
import { Mixpanel } from "../../services/MixPanel";

import NikeLogo from "../../assets/giftCards/Nike-Gift-Card.png";
import GreggsLogo from "../../assets/giftCards/greggs_logo.png";
import HnMLogo from "../../assets/giftCards/hm-symbol-logo.png";
import JustEatLogo from "../../assets/giftCards/Just_Eat.png";

const Homepage = () => {
  const route = useNavigate();
  const data = [
    { brand: "Nike", logo: NikeLogo },
    { brand: "Just Eat", logo: JustEatLogo },
    { brand: "H&M", logo: HnMLogo },
    { brand: "Greggs", logo: GreggsLogo },
  ];
  return (
    <>
      <Header />
      <Box className="viewFooter">
        <GetDiscount />
        <Box mb={8} style={{maxWidth: '1441px', marginLeft: 'auto', marginRight: 'auto'}}>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={3}
            p={3}>
            <Typography
              fontWeight={600}
              fontSize={{ xs: 20, sm: 36 }}
              lineHeight={{ xs: "30px", sm: "44px" }}
              letterSpacing={"-0.72px"}
              color={"#101828"}>
              Recommended Brands
            </Typography>
            <div
              //onClick={() => catRoute("Fine Dining")}
              className="viewAllLinkDesktop"
              style={{ cursor: "pointer" }}>
              View All
            </div>
          </Grid>
          <div
            style={{
              // border: "2px solid red",
              display: "flex",
              margin: "0px 20px",
              justifyContent: "space-between",
              flexWrap: "wrap",
              width: "97%",
              height: 200,
            }}>
            {data.map((item) => {
              return (
                <div
                  style={{
                    width: 300,
                    //height: 300,
                    position: "relative",
                    // border: "1px solid grey",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    //console.log("first");
                    route(`/details/${item.brand}`);
                  }}>
                  <img
                    src={item.logo}
                    //alt={offerInfo.brand_name}
                    style={{ width: 300, height: 200 }}
                  />
                  <Typography
                    fontWeight={700}
                    marginLeft={1}
                    fontSize={{ xs: 10, sm: 18 }}
                    lineHeight={{ xs: "30px", sm: "44px" }}
                    letterSpacing={"-0.72px"}
                    color={"#101828"}>
                    {item.brand}
                  </Typography>
                  <Box
                    bgcolor={"#F2F4F7"}
                    borderRadius={"4px"}
                    paddingX={"15px"}
                    paddingY={"2px"}
                    fontWeight={700}
                    fontSize={"14px"}
                    color={"#344054"}
                    position={"absolute"}
                    top={"16px"}
                    left={"12px"}>
                    10% Cashback
                  </Box>
                </div>
              );
            })}
          </div>
        </Box>
        {/* <SaveMoney />
        <Footer /> */}
      </Box>
      <BottomMenu />
    </>
  );
};

export default Homepage;
