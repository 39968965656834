import { Navigate } from "react-router-dom";
import { FsService } from "../services/fingerPrint";
import { selectionApi } from "../services/HomeApi";

export const getUserInfo = () => {
  if (typeof window === "undefined") {
    return false;
  }
  // if (localStorage.getItem("userDetails")) {
  //   return JSON.parse(localStorage.getItem("userDetails"));
  // } else {
  //   const userId = JSON.parse(localStorage.getItem("FingerPrintData"));
  //   return { userId };
  // }
  if (localStorage.getItem("userDetails")) {
    return JSON.parse(localStorage.getItem("userDetails"));
  } else if (localStorage.getItem("uuid")) {
    return { userId: localStorage.getItem("uuid") };
  }
};

export const isAuthenticate = () => {
  if (typeof window === "undefined") {
    return false;
  }
  if (localStorage.getItem("isLoginWithGoogle")) {
    return JSON.parse(localStorage.getItem("isLoginWithGoogle"));
  } else if (
    localStorage.getItem("accessToken") &&
    localStorage.getItem("userDetails")
  ) {
    return JSON.parse(localStorage.getItem("accessToken"));
  } else {
    return false;
  }
};

export const isAuthenticateByApi = async () => {
  const user = await FsService();
  const doLoad = await selectionApi({ customer_id: user.userId });
  if (doLoad && doLoad.status === 200) {
    if (true) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
