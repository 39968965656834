import { getUserInfo } from "../auth/index";
import { apiStackTrack } from "./HomeApi";

export const urlFinder = () => {
  return window.location.port;
};

export const errorReport = async (error, message = undefined) => {
  let user = getUserInfo();
  const body = {
    customer_id: user?.userId ? user.userId : "",
    // stack_trace: message ? JSON.stringify(error) : JSON.stringify(error.stack),
    stack_trace: error?.message || "stack_trace",
    error_message: error?.response?.data || "CORS Error",
  };
  try {
    let stk = await apiStackTrack(body);
    if (stk) {
    }
  } catch (error) {}
};
