import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import HomeIcon from "../assets/HomeIcon.svg";
import HeartIcon from "../assets/HeartIcon.svg";
import SavingIcon from "../assets/SavingIcon.svg";
import SearchIcon from "../assets/SearchIconW.svg";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

import { Link, useLocation } from "react-router-dom";
import { en } from "../appLabels/engLabels";
import { getBankName } from "../utils";

const FooterMenu = () => {
  const path = useLocation().pathname;

  const getActiveLink = (link) => {
    let classes = "";
    if (path === link || path.includes(link)) {
      classes = "active-blu brandombold";
    } else {
      classes = "link-blk brandombold";
    }

    return classes;
  };

  return (
    <Stack
      className="bottom-menu"
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Box textAlign="center">
        <Link to="/home" className={getActiveLink("/home")}>
          <Box className="fmenu-b">
            <img src={HomeIcon} alt="home" />
          </Box>
          <Typography as="p" variant="body4" className={getActiveLink("/home")}>
            {en.footerMenuHome}
          </Typography>
        </Link>
      </Box>
      <Divider orientation="vertical" className="bottom-menu-divider" />
      <Box>
        <Link to="/favorites" className={getActiveLink("/favorites")}>
          <Box className="fmenu-b">
            <img src={HeartIcon} alt="Favourites" />
          </Box>

          <Typography
            as="p"
            variant="body4"
            className={getActiveLink("/favorites")}
          >
            {en.footerMenuFav}
          </Typography>
        </Link>
      </Box>
      <Divider orientation="vertical" className="bottom-menu-divider" />

      <Box>
        <Link
          to={getBankName() === "travx" ? "/find" : "/location"}
          className={getActiveLink(
            getBankName() === "travx" ? "/find" : "/location"
          )}
        >
          <Box className="fmenu-b">
            {getBankName() === "travx" ? (
              <img src={SearchIcon} alt="Favourites" />
            ) : (
              <RoomOutlinedIcon
                sx={{
                  color: "#fff",
                  fontSize: "1.3rem",
                  marginLeft: "-2px",
                  marginTop: "-2px",
                }}
              />
            )}
          </Box>
          <Typography as="p" variant="body4" className={getActiveLink("/find")}>
            {en.footerMenuSearch}
          </Typography>
        </Link>
      </Box>
      {getBankName() === "travx" ? (
        <>
          <Divider orientation="vertical" className="bottom-menu-divider" />

          <Box>
            <Link to="/savings" className={getActiveLink("/savings")}>
              <Box className="fmenu-b">
                <img src={SavingIcon} alt="Favourites" />
              </Box>

              <Typography
                as="p"
                variant="body4"
                className={getActiveLink("/savings")}
              >
                {en.footerMenuSavings}
              </Typography>
            </Link>
          </Box>
        </>
      ) : null}
    </Stack>
  );
};

export default FooterMenu;
