import React, { useState, useEffect } from "react";
import { Box, Stack, IconButton } from "@mui/material";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { feedBackApi } from "../services/HomeApi";
import { Mixpanel } from "../services/MixPanel";
import { getBankName } from "../utils";

const FeedBack = (props) => {
  const [feed, setFeed] = useState({
    fav: props.favourite ? props.favourite : false,
    like: props.like ? props.like : false,
    dislike: props.dislike ? props.dislike : false,
  });

  useEffect(() => {
    setFeed({
      fav: props.favourite ? props.favourite : false,
      like: props.like ? props.like : false,
      dislike: props.dislike ? props.dislike : false,
    });
  }, [props]);

  const getFeedBack = async (v) => {
    const user = JSON.parse(localStorage.getItem("userDetails"));
    const ApiBody = {
      customer_id: user.userId,
      offer_id: props.offerId,
      branch_id: props.branchId,
      feedback: v,
    };
    const apiBack = await feedBackApi(ApiBody);
    Mixpanel.track("User Feedback", {
      userId: user.userId,
      offer_id: props.offerId,
      branch_id: props.branchId,
      feedback: v,
    });
    return apiBack;
  };

  const changeHandler = async (type, e) => {
    e.stopPropagation();
    let res = "";
    switch (type) {
      case "fav":
        setFeed({ ...feed, fav: !feed.fav });
        res = await getFeedBack(0);
        if (res.status !== 200) {
          setFeed({ ...feed, fav: !feed.fav });
        }
        break;
      case "like":
        setFeed({ ...feed, like: !feed.like, dislike: false });
        res = await getFeedBack(1);
        if (res.status !== 200) {
          setFeed({ ...feed, like: !feed.like, dislike: false });
        }

        break;
      case "dislike":
        setFeed({ ...feed, like: false, dislike: !feed.dislike });
        res = await getFeedBack(-1);

        if (res.status !== 200) {
          setFeed({ ...feed, like: false, dislike: !feed.dislike });
        }
        break;
      default:
        res = await getFeedBack(0);
        if (res.status === 200) {
          setFeed(setFeed({ ...feed }));
        }
        break;
    }
  };
  return (
    <>
      <Stack
        direction="row"
        alignItems="baseline"
        gap="6px"
        //className={getBankName() === "travx" ? "card-action" : ""}
      >
        <Box>
          <IconButton className="p-0" onClick={(e) => changeHandler("fav", e)}>
            {feed.fav ? (
              <FavoriteIcon sx={{ color: "red", fontSize: 20 }} />
            ) : (
              <FavoriteIcon sx={{ color: "#D0D5DD", fontSize: 20 }} />
            )}
          </IconButton>
        </Box>
        {/* {getBankName() === "travx" && (
          <>
            <Box>
              <IconButton
                className="p-1"
                onClick={(e) => changeHandler("like", e)}
              >
                {feed.like ? (
                  <ThumbUpIcon fontSize="small" />
                ) : (
                  <ThumbUpOffAltIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
            <Box>
              <IconButton
                className="p-1"
                onClick={(e) => changeHandler("dislike", e)}
              >
                {feed.dislike ? (
                  <ThumbDownIcon fontSize="small" />
                ) : (
                  <ThumbDownOffAltIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
          </>
        )} */}
      </Stack>
    </>
  );
};

export default FeedBack;
