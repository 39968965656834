import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

const PriceDropDown = (props) => {
	const [price, setPrice] = useState("");
	const handleChange = (e) => {
		const val = e.target.value;
		setPrice(val);
		props.updatePrice(val);
	};
	return (
		<FormControl sx={{ marginRight: "12px" }}>
			<Select
				value={price}
				onChange={handleChange}
				displayEmpty
				className="listViewSelect"
				height='60px'
			>
				<MenuItem value="">Price</MenuItem>
				<MenuItem value={'£'}>£</MenuItem>
				{/* <MenuItem value={'££'}>££</MenuItem> */}
				<MenuItem value={"££-£££"}>££-£££</MenuItem>
				<MenuItem value={"£££"}>£££</MenuItem>
				<MenuItem value={"££££"}>££££</MenuItem>
			</Select>
		</FormControl>

	)
}

export default PriceDropDown;
