import React from "react";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Logo from "../assets/TRAVXLOGO.svg";
import CloseIcon from "@mui/icons-material/Close";
import House from "../assets/home.svg";
import TransactionHisory from "../assets/TransactionHistory.svg";
import Mycard from "../assets/mycards.svg";
import Legal from "../assets/legal.svg";
import Help from "../assets/help.svg";
import Offer from "../assets/offers.svg";
import Profile from "../assets/Profile.svg";
import { Link, useNavigate } from "react-router-dom";
import { en } from "../appLabels/engLabels";
import { getBankName, logoLoader } from "../utils";
import AppsIcon from "@mui/icons-material/Apps";
import { useWindowSize } from "@react-hook/window-size";
import HeartIcon from "../assets/HeartIcon-B.svg";
import SavingIcon from "../assets/Savings-B.svg";
import MapIcon from "@mui/icons-material/Map";
import ArticleIcon from "@mui/icons-material/Article";
import ErrorBoundary from "../ErrorBoundary";
import { Mixpanel } from "../services/MixPanel";

const SideBar = (props) => {
  const route = useNavigate();
  const bank = getBankName();
  const [width] = useWindowSize();

  const MixPanelTrackNav = (nav) => {
    // const user = JSON.parse(localStorage.getItem("userDetails"));
    // Mixpanel.track("Sidebar Menu Items Clicked", {
    //   userId: user.userId,
    //   nav_name: nav,
    // });
  };
  const signout = () => {
    let tos = JSON.parse(localStorage.getItem("tos_accepted"));

    localStorage.clear();
    localStorage.setItem("tos_accepted", JSON.stringify(tos));
    route("/signout");
  };

  return (
    <Container>
      <ErrorBoundary>
        <Stack
          gap="2rem"
          sx={{
            width: "270px",
            paddingTop: "1rem",
            paddingRight: "1rem",
            minHeight: "90vh",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Box className="sidebar-logo">
                <img src={logoLoader()} alt="logo" />
              </Box>
            </Box>
            <Box>
              <CloseIcon fontSize="large" onClick={() => props.close()} />
            </Box>
          </Stack>
          <nav style={{ height: "100%" }}>
            <List>
              <Link
                to="/home"
                style={{ color: "inherit" }}
                onClick={() => {
                  MixPanelTrackNav("Home");
                  props.close();
                }}
              >
                <ListItem disableGutters>
                  <ListItemButton>
                    <ListItemIcon>
                      <img className="list-icon" src={House} alt="home" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="h6"
                        letterSpacing="0.5px"
                        className="brandombold"
                      >
                        {en.hamMenuHome}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>

              {getBankName() === "travx" && (
                <Link
                  to="/find"
                  style={{ color: "inherit" }}
                  onClick={() => {
                    MixPanelTrackNav("Find");
                    props.close();
                  }}
                >
                  <ListItem disableGutters>
                    <ListItemButton>
                      <ListItemIcon>
                        <img className="list-icon" src={Offer} alt="offer" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          letterSpacing="0.5px"
                          className="brandombold"
                        >
                          {en.hamMenuOffers}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
              )}

              {width > 894 && (
                <Link
                  to="/favorites"
                  style={{ color: "inherit" }}
                  onClick={() => {
                    MixPanelTrackNav("Favorities");
                    props.close();
                  }}
                >
                  <ListItem disableGutters>
                    <ListItemButton>
                      <ListItemIcon>
                        <img src={HeartIcon} alt="Favourites" width="25px" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          letterSpacing="0.5px"
                          className="brandombold"
                        >
                          Favorites
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
              )}

              {bank === "travx" && width > 894 && (
                <Link
                  to="/savings"
                  style={{ color: "inherit" }}
                  onClick={() => {
                    MixPanelTrackNav("Savings");
                    props.close();
                  }}
                >
                  <ListItem disableGutters>
                    <ListItemButton>
                      <ListItemIcon>
                        <img src={SavingIcon} alt="Favourites" width="25px" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          letterSpacing="0.5px"
                          className="brandombold"
                        >
                          Savings
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
              )}

              {bank !== "travx" ? (
                <Link
                  to="/all-brands"
                  style={{ color: "inherit" }}
                  onClick={() => {
                    MixPanelTrackNav("All Brands");
                    props.close();
                  }}
                >
                  <ListItem disableGutters>
                    <ListItemButton>
                      <ListItemIcon>
                        <AppsIcon sx={{ color: "#8FA2AA" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          letterSpacing="0.5px"
                          className="brandombold"
                        >
                          All Brands
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
              ) : null}

              {bank === "travx" ? (
                <>
                  <Link
                    to="/transaction-history"
                    style={{ color: "inherit" }}
                    onClick={() => {
                      MixPanelTrackNav("Transaction History");
                      props.close();
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemButton>
                        <ListItemIcon>
                          <img
                            className="list-icon"
                            src={TransactionHisory}
                            alt="history"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            letterSpacing="0.5px"
                            className="brandombold"
                          >
                            {en.hamMenuMyTransaction}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                  <Link
                    to="/cards"
                    style={{ color: "inherit" }}
                    onClick={() => {
                      MixPanelTrackNav("Cards");
                      props.close();
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemButton>
                        <ListItemIcon>
                          <img
                            className="list-icon"
                            src={Mycard}
                            alt="my card"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            letterSpacing="0.5px"
                            className="brandombold"
                          >
                            {en.hamMenuMyCard}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Link>

                  <Link
                    to="/profile"
                    style={{ color: "inherit" }}
                    onClick={() => {
                      MixPanelTrackNav("Profile");
                      props.close();
                    }}
                  >
                    <ListItem disableGutters>
                      <ListItemButton>
                        <ListItemIcon>
                          <img
                            className="list-icon profile-icon"
                            src={Profile}
                            alt="Legal"
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            letterSpacing="0.5px"
                            className="brandombold"
                          >
                            {en.hamMenuProifile}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </>
              ) : null}

              {/* <Link
                to="/location"
                style={{ color: "inherit" }}
                onClick={() => {
                  MixPanelTrackNav("Location");
                  props.close();
                }}
              >
                <ListItem disableGutters onClick={() => props.close()}>
                  <ListItemButton>
                    <ListItemIcon>
                      <MapIcon sx={{ color: "#8FA2AA" }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="h6"
                        letterSpacing="0.5px"
                        className="brandombold"
                      >
                        Maps
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link> */}

              <Link
                to={getBankName() === "travx" ? "/legals" : "/terms"}
                style={{ color: "inherit" }}
                onClick={() => {
                  MixPanelTrackNav("Terms");
                  props.close();
                }}
              >
                <ListItem disableGutters onClick={() => props.close()}>
                  <ListItemButton>
                    <ListItemIcon>
                      {getBankName() === "travx" ? (
                        <img className="list-icon" src={Legal} alt="Legal" />
                      ) : (
                        <ArticleIcon sx={{ color: "#8FA2AA" }} />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="h6"
                        letterSpacing="0.5px"
                        className="brandombold"
                      >
                        {getBankName() === "travx" ? en.hamMenuLegal : "Terms"}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link
                to="/help"
                style={{ color: "inherit" }}
                onClick={() => {
                  MixPanelTrackNav("Help");
                  props.close();
                }}
              >
                <ListItem disableGutters>
                  <ListItemButton>
                    <ListItemIcon>
                      <img className="list-icon" src={Help} alt="Help" />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        variant="h6"
                        letterSpacing="0.5px"
                        className="brandombold"
                      >
                        {en.hamMenuHelp}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
          </nav>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            padding="1rem"
            sx={{ marginTop: "auto" }}
          >
            <Box>
              {bank == "travx" ? (
                <Button
                  variant="text"
                  className="brandomtxt-regular"
                  sx={{
                    textDecoration: "underline",
                    color: "#8FA2AA",
                    fontSize: "1.2rem",
                  }}
                  onClick={signout}
                >
                  Sign out
                </Button>
              ) : null}
            </Box>
            <Box>
              <Typography
                as="p"
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "1.2rem" }}
                className="brandomtxt-regular"
              >
                Version 1.0
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </ErrorBoundary>
    </Container>
  );
};

export default SideBar;
