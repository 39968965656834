import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";
import { routes } from "./routes";

const ScrollToTop = lazy(() => import("./ScrollToTop"));
const Router = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
