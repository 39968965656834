import React, { useEffect, useState } from "react";
import {
  Stack,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { searchApi } from "../services/HomeApi";
import { ToastMessage } from "./ToastMessage";
import { getUserInfo } from "../auth";
import { Mixpanel } from "../services/MixPanel";

const MixPanelLocationRoute = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  Mixpanel.track("SeachBar", {
    userId: user.userId,
    type : "location",
  });
};

const MixPanelCatRoute = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  Mixpanel.track("SeachBar", {
    userId: user.userId,
    type : "Cuisine"
  });
};

const MixPanelPinRoute = () => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  Mixpanel.track("SeachBar", {
    userId: user.userId,
    type : "pinCode"
  });
};

const MixPanelSearchBar = (obj) => {
  const user = JSON.parse(localStorage.getItem("userDetails"));
  Mixpanel.track("SeachBar", {
    userId: user.userId,
    offer_id: obj.offer_id,
    branch_id: obj.branch_id,
    offer_name : obj.offer_name,
    type : "restaurant"
  });
}

const Dropdown = (props) => {

  const route = useNavigate();
  const [offer, setOffer] = useState([]);
  const [cusineName, setCusineName] = useState([]);
  const [location, setLocation] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [loading, setLoading] = useState(false);
  const getSearchOfer = async () => {
    try {
      setLoading(true);
      const user = getUserInfo();
      const schBody = {
        customer_id: user.userId,
        search_text_offer_name: props.data,
        relevant_offers: props.relevantOffers,
      };
      const searchRes = await searchApi(schBody);

      if (searchRes.status === 200) {
        setOffer([...searchRes.data.search_result]);
        setCusineName([...searchRes.data.cuisine_name]);
        setLocation([...searchRes.data.location_name]);
        setPincode([...searchRes.data.pincode_name]);
        setLoading(false);
      } else {
        setLoading(false);
        ToastMessage("Somthing went wrong!", true);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getSearchOfer();
  }, [props]);

  const catRoute = (id) => {
    if (id === "Vegetarian") {
      id = "Vegetarian Friendly"
    }
    if (id === "Steaks") {
      id = "steak";
    }
    localStorage.setItem("activeCategory", JSON.stringify(id));
    const permaLink = id.toLowerCase().replace(/[^A-Z0-9]+/gi, "-");
    route(`/offers/category/${permaLink}`);
  };
  const locationRoute = (id) => {
    const permaLink = id.toLowerCase().replace(/[^A-Z0-9]+/gi, "-");
    route({
      pathname: "/offer/all",
      search: `?location=${permaLink}`,
    });
  };
  const pinRoute = (id) => {
    const permaLink = id.replace(/[^A-Z0-9]+/gi, "-");
    route({
      pathname: "/offer/all",
      search: `?pincode=${permaLink}`,
    });
  };

  return (
    <nav className="drop-down">
      {loading ? (
        <Stack direction="row" justifyContent="center" marginTop="1rem">
          <CircularProgress sx={{ color: "#000" }} />
        </Stack>
      ) : (
        <>
          {offer.length ||
          cusineName.length ||
          pincode.length ||
          location.length ? (
            <List>
              {location.length > 0 && (
                <>
                  {location.map((loc) => {
                    return (
                      <div
                        key={loc}
                        onClick={() => {
                          locationRoute(loc);
                          MixPanelLocationRoute();
                        }}
                        sx={{
                          color: "#000",
                          borderBottom: "1px solid #000",
                        }}
                      >
                        <ListItem disableGutters disablePadding>
                          <ListItemButton
                            sx={{
                              borderBottom: "1px solid #fff",
                              paddingLeft: "unset",
                              margin: "0 20px",
                            }}
                          >
                            <ListItemText
                              sx={{
                                textTransform: "capitalize",
                              }}
                            >
                              <Typography
                                as="p"
                                fontSize="inherit"
                                className="brandomtxt-regular lt-0 text-capitalize"
                              >
                                Restaurants near {loc}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </div>
                    );
                  })}
                </>
              )}
              {cusineName.length > 0 && (
                <>
                  {cusineName.map((cuisine) => {
                    return (
                      <div
                        key={cuisine}
                        onClick={() => {
                          catRoute(cuisine);
                          MixPanelCatRoute();
                        }}
                        // to={`/offers/category/${cusineName}`}
                        sx={{
                          color: "#000",
                          borderBottom: "1px solid #000",
                        }}
                      >
                        <ListItem disableGutters disablePadding>
                          <ListItemButton
                            sx={{
                              borderBottom: "1px solid #fff",
                              paddingLeft: "unset",
                              margin: "0 20px",
                            }}
                          >
                            <ListItemText
                              sx={{
                                textTransform: "capitalize",
                              }}
                            >
                              <Typography
                                as="p"
                                fontSize="inherit"
                                className="brandomtxt-regular lt-0"
                              >
                                {cuisine} restaurants near me
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </div>
                    );
                  })}
                </>
              )}
              {pincode.length > 0 && (
                <>
                  {pincode.map((cuisine) => {
                    return (
                      <p
                        key={cuisine}
                        onClick={() => {
                          pinRoute(cuisine);
                          MixPanelPinRoute();
                        }}
                        sx={{
                          color: "#000",
                          borderBottom: "1px solid #000",
                        }}
                      >
                        <ListItem disableGutters disablePadding>
                          <ListItemButton
                            sx={{
                              borderBottom: "1px solid #fff",
                              paddingLeft: "unset",
                              margin: "0 20px",
                            }}
                          >
                            <ListItemText
                              sx={{
                                textTransform: "capitalize",
                              }}
                            >
                              <Typography
                                as="p"
                                fontSize="inherit"
                                className="brandomtxt-regular lt-0"
                              >
                                Restaurants near {cuisine}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        </ListItem>
                      </p>
                    );
                  })}
                </>
              )}
              {offer.map((item, key) => {
                return (
                  <Link
                    to={`/details/${item.offer_id}/${item.branch_id}`}
                    onClick={()=>{
                      MixPanelSearchBar(item);
                      setOffer([]);
                      props.setSearchValue("");
                      console.log(`/details/${item.offer_id}/${item.branch_id}`);
                    }}
                    style={{
                      color: "#000",
                      borderBottom: "1px solid #000",
                    }}
                    key={key}
                  >
                    <ListItem disableGutters disablePadding key={key}>
                      <ListItemButton
                        sx={
                          offer.length - 1 > key
                            ? {
                                borderBottom: "1px solid #fff",
                                paddingLeft: "unset",
                                margin: "0 20px",
                              }
                            : {}
                        }
                      >
                        <ListItemText>
                          <Typography
                            as="p"
                            fontSize="inherit"
                            className="brandomtxt-regular lt-0"
                          >
                            {item.offer_name}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                );
              })}
            </List>
          ) : (
            <List>
              <ListItem>
                <ListItemText>
                  <Typography
                    as="p"
                    fontSize="inherit"
                    className="brandomtxt-regular lt-0"
                  >
                    Could not find any matching Restaurants
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          )}
        </>
      )}
    </nav>
  );
};

export default Dropdown;
