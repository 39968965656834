import React, { useState ,useEffect } from "react";
import { Stack, Typography, Box, Container, Drawer, rgbToHex } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../assets/TRAVXLOGO.svg";
import SearchIcon from "../../assets/SearchIcon.svg";

import SideBar from "../SideBar";
import { Link } from "react-router-dom";
import { logoLoader } from "../../utils";
import { Mixpanel } from "../../services/MixPanel";
import ErrorBoundary from "../../ErrorBoundary";
import Dropdown from "../Dropdown";

const AuthHeader = (props) => {
  const [sideBar, setSideBar] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [relevantOffers, setRelevantOffers] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Set the threshold width to consider the screen as mobile (adjust as needed)
      const mobileThreshold = 768;
      setIsMobile(window.innerWidth < mobileThreshold);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchValue(val);
  };

  const toggleDrawer = (event) => {
    console.log("hgchgcghcg");
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSideBar(!sideBar);

    const user = JSON.parse(localStorage.getItem("userDetails"));
    Mixpanel.track("Sidebar Menu Button Clicked", {
      userId: user.userId,
    });
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="py-1"
      >
        <Box>
          <MenuIcon onClick={toggleDrawer} sx={{ fontSize: "2rem" }} />
        </Box>
        {props.title ? (
          <Box>
            <Typography
              as="h4"
              variant="h4"
              className="brandombold"
              color="#4B4C4E"
            >
              {props.title}
            </Typography>
          </Box>
        ) : null}

        {props.logo ? (
          <Box>
            <Link to="/home">
              <img src={logoLoader()} alt="logo" className="header-logo" />
            </Link>
          </Box>
        ) : null}
        {/* <Box>
          {props.search ? (
            <Link to="/find">
              <img
                src={SearchIcon}
                alt="search icon"
                className="header-search-icon"
              />
            </Link>
          ) : null}
        </Box> */}
        <Stack direction={"row"} alignItems={"center"} spacing={3}>
{!isMobile ?               <Box width={337} height={48} position={"relative"}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "8px",
                    top: "50%",
                    transform: "TranslateY(-50%)",
                    width: 32,
                    height: 32,
                    backgroundColor: "#CEE9EF",
                    borderRadius: "100px",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
              <img
                width={"16px"}
                src={SearchIcon}
                alt="search icon"
                //className="header-search-icon"
              />
                </Box>
                <input
                //onFocus={this.value=""}
                  type="text"
                  placeholder="Restaurant, Cuisine or Area"
                  style={{
                    outline: "none",
                    background: "#FFFFFF",
                    boxShadow: "0px 1px 2px 0px rgba(31, 41, 55, 0.08)",
                    borderRadius: "1000px",
                    border: "1px solid #EAECF0",
                    width: "100%",
                    height: "100%",
                    paddingLeft: "24px",
                  }}
                  onChange={handleSearch}
                />

                {/* <SearchIcon/> */}
                {searchValue ? (
                  <ErrorBoundary>
                    <Dropdown data={searchValue} relevantOffers={relevantOffers}  setSearchValue={setSearchValue}/>
                  </ErrorBoundary>
                ) : null}
              </Box> :
              <Link to="/search-page">
                <img
                  width={"16px"}
                  src={SearchIcon}
                  alt="search icon"
                />
              </Link>
              }
              {/* <Link to="/favorites"
                onClick={()=>{
                  MixPanelTrackNav("Favorites");
                }}            
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #D0D5DD",
                    background: "#fff",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#344054",
                    width: "146px",
                    height: "44px",
                  }}
                >
                  <FavoriteBorderIcon
                    sx={{
                      width: 20,
                      height: 20,
                      marginRight: "8px",
                      color: "#344054",
                    }}
                  />
                  Favourites
                </Button>
              </Link> */}

            </Stack>
        {props.children ? <Box>{props.children}</Box> : null}
      </Stack>
      <Drawer anchor="left" open={sideBar} onClose={toggleDrawer}>
        <SideBar close={toggleDrawer} />
      </Drawer>
    </Container>
  );
};

export default AuthHeader;
