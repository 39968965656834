import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/homepage/Header";
import Footer from "../../components/homepage/Footer";
import {
  Button,
  Container,
  Grid,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import "../../components/homepage/homepage.css";
import DetailsBox from "../../components/homepage/DetailsBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getUserInfo } from "../../auth";
import ErrorBoundary from "../../ErrorBoundary";
import { getOfferDetails } from "../../services/HomeApi";
import BottomMenu from "../../components/homepage/BottomMenu";
import { Mixpanel } from "../../services/MixPanel";
import { bookNow } from "../../services/HomeApi";
import { getBankName } from "../../utils";
import AuthHeader from "../../components/headers/AuthHeader";

import NikeLogo from "../../assets/giftCards/Nike-Gift-Card.png";
import GreggsLogo from "../../assets/giftCards/greggs_logo.png";
import HnMLogo from "../../assets/giftCards/hm-symbol-logo.png";
import JustEatLogo from "../../assets/giftCards/Just_Eat.png";
const Details = () => {
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = React.useState({});
  const params = useParams();
  const route = useNavigate();
  const [logo, setLogo] = useState(NikeLogo);
  const [brand, setBrand] = useState(params.brand);
  //console.log(params);
  const goToPreviousPath = () => {
    route(-1);
  };
  useEffect(() => {
    switch (params.brand) {
      case "Nike":
        setLogo(NikeLogo);
        break;
      case "Just Eat":
        setLogo(JustEatLogo);
        break;
      case "H&M":
        setLogo(HnMLogo);
        break;
      case "Greggs":
        setLogo(GreggsLogo);
        break;
      default:
        setLogo(NikeLogo);
        break;
    }
  }, []);
  // useEffect(() => {
  //   const recommendedOffers = JSON.parse(
  //     localStorage.getItem("recommendedOffers")
  //   );

  //   const branchObj = recommendedOffers?.filter(
  //     (offer) => Object.keys(offer)[0] === params.id
  //   );
  //   let bid = JSON.parse(localStorage.getItem("currentBranchId"))
  //     ? JSON.parse(localStorage.getItem("currentBranchId"))
  //     : "";
  //   let branchID = "";
  //   if (branchObj) {
  //     branchID = branchObj[0] ? branchObj[0][params.id] : bid;
  //   } else {
  //     branchID = bid;
  //   }

  //   // if (!params.id) {
  //   //   route("/home");
  //   // }
  //   offerDetails(branchID);
  // }, [params]);
  // const offerDetails = async (branchID) => {
  //   try {
  //     setLoading(true);

  //     const user = getUserInfo();
  //     const body = {
  //       customer_id: user.userId,
  //       offer_id: params.id,
  //       is_detailed: 1,
  //       branch_id: params.branchID,
  //     };
  //     const info = await getOfferDetails(body);

  //     if (info.status === 200) {
  //       setOffer({ ...info.data });
  //       localStorage.setItem("TOS", JSON.stringify(info.data.restaurant_tos));
  //       setLoading(false);
  //     }
  //   } catch (error) {}
  // };

  return (
    <>
      <Header />
      <Box style={{ background: "#fff" }}>
        <Container maxWidth="lg" className="viewDetailsContainer">
          {/* {loading ? (
            <Box className="d-flex justify-content-center">
              <CircularProgress />
            </Box>
          ) :  */}

          <Grid
            container
            direction="row"
            spacing={"50px"}
            mb={12}
            marginTop={getBankName() === "travx" ? "0px" : ""}>
            {/* <Grid item xs={12}>
            <Button
              variant="outlined"
              sx={{
                width: 135,
                height: 60,
                borderRadius: "8px",
                border: "1px solid #D0D5DD",
                background: "white",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                fontWeight: 600,
                fontSize: 18,
                color: "#344054",
                gap: "12px",
              }}
              onClick={goToPreviousPath}
            >
              <ArrowBackIcon />
              Back
            </Button>
          </Grid> */}
            {/* <Grid container alignItems="center"
          spacing={"50px"}
          direction="row"
          mb={12}> */}
            <ErrorBoundary>
              <Grid item xs={12} sm={6}>
                <div style={{ width: "100%", position: "relative" }}>
                  <img src={logo} alt="" style={{ width: "100%" }} />
                  <Box
                    bgcolor={"#F2F4F7"}
                    borderRadius={"4px"}
                    paddingX={"18px"}
                    paddingY={"3px"}
                    fontWeight={700}
                    fontSize={"15px"}
                    color={"#344054"}
                    position={"absolute"}
                    top={"20px"}
                    left={"20px"}>
                    10% Cashback
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} className="viewDetailsWhiteBox">
                <DetailsBox brandName={brand} />
              </Grid>
            </ErrorBoundary>
          </Grid>
          {/* } */}
          {/* </Grid> */}
        </Container>
        {/* {getBankName() !== "travx" ? <Footer /> : <></>} */}
      </Box>
      <BottomMenu />
    </>
  );
};

export default Details;
