import { Navigate } from "react-router-dom";
import { isAuthenticate } from "./index";

 const IsLoggedIn = ({ children,from }) => {
  const token = isAuthenticate()
  if (token) {

    // user is not authenticated
    return <Navigate to="/home" />;
  }
const isUrlVerified = JSON.parse(localStorage.getItem("urlverified"));
  if(!isUrlVerified && !from){
    return <Navigate to="/"/>
  }
  return children;
};

export default IsLoggedIn
