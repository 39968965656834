import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { getAreaApi } from "../../services/HomeApi";
import { ToastMessage } from "../ToastMessage";
import { toUpperCaseEachWord } from "../../services/utility";

const AreaDropDown = (props) => {
  const [area, setArea] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const handleChange = (e) => {
    const val = e.target.value;
    setArea(val);
    props.updateArea(val);
  };
  useEffect(() => {
    // getArea();
    setAreaList([
      "aberdeen",
      "acton",
      "aldgate",
      "ashford",
      "bank of england",
      "barbican",
      "barnes",
      "barnsbury",
      "bath",
      "battersea",
      "bayswater",
      "belfast",
      "belgravia",
      "bermondsey",
      "beverley",
      "birmingham",
      "blackburn",
      "bloomsbury",
      "bolton",
      "borough",
      "bounds green",
      "bournemouth",
      "bowes park",
      "brackenbury village",
      "brighton",
      "bristol",
      "brixton",
      "broadgate",
      "bromley",
      "bromley by bow",
      "brompton",
      "bunhill fields",
      "bury st. edmunds",
      "cambridge",
      "camden town",
      "canary wharf",
      "canonbury",
      "cardiff",
      "carlisle",
      "chancery lane",
      "cheadle",
      "chelmsford",
      "chelsea",
      "cheltenham",
      "chessington",
      "chester",
      "chinatown",
      "chiswick",
      "clapham",
      "clapham junction",
      "clerkenwell",
      "colchester",
      "colliers wood",
      "covent garden",
      "coventry",
      "croydon",
      "cubitt town",
      "dalston",
      "darlington",
      "derby",
      "docklands",
      "dublin",
      "dudley",
      "durham",
      "ealing",
      "earlsfield",
      "edinburgh",
      "ellesmere port",
      "enfield",
      "exeter",
      "falmouth",
      "farnham",
      "farringdon",
      "finsbury",
      "finsbury park",
      "fistral",
      "fitzrovia",
      "fleet street",
      "friern barnet",
      "fulham",
      "glasgow",
      "grays inn",
      "greenhithe",
      "greenwich",
      "grosvenor square",
      "guildford",
      "guildhall",
      "hackney",
      "hackney marshes",
      "hammersmith",
      "hampstead",
      "hanover square",
      "harrogate",
      "harrow",
      "heswall",
      "high wycombe",
      "hither green",
      "holborn",
      "holland park",
      "hounslow",
      "hull",
      "isle of dogs",
      "islington",
      "kensington",
      "kilburn",
      "kings cross central",
      "kingston",
      "kingston upon thames",
      "kingston vale",
      "knightsbridge",
      "knutsford",
      "lambeth",
      "leamington spa",
      "leeds",
      "leicester",
      "lewisham",
      "leyton",
      "limehouse",
      "lincoln",
      "lincoln's inn fields",
      "liverpool",
      "liverpool street",
      "llandudno",
      "london",
      "maida vale",
      "manchester",
      "manor house",
      "marlow",
      "marylebone",
      "mayfair",
      "merton",
      "mile end",
      "millwall",
      "milton keynes",
      "monument",
      "moorgate",
      "morden",
      "mortlake",
      "motherwell",
      "new cross",
      "new southgate",
      "newcastle",
      "newcastle upon tyne",
      "norwich",
      "notting hill",
      "nottingham",
      "nunhead",
      "olympic park",
      "oxford",
      "paddington",
      "paisley",
      "parsons green",
      "peckham",
      "piccadilly",
      "pimlico",
      "plymouth",
      "poplar",
      "portsmouth",
      "primrose hill",
      "princesshay",
      "putney",
      "queens park",
      "reading",
      "regents park",
      "reigate",
      "richmond",
      "roehampton",
      "romford",
      "rotherhithe",
      "royal academy",
      "royal courts of justice",
      "saint pancras",
      "savile row",
      "sheffield",
      "shepherd's bush",
      "shepherds bush",
      "soho",
      "somers town",
      "south bank",
      "south bermonsey",
      "south kensington",
      "south wimbledon",
      "southampton",
      "southwark",
      "st albans",
      "st bartholomew's hospital",
      "st giles",
      "st james's",
      "st john's wood",
      "st luke's",
      "st mary axe",
      "st paul's",
      "st. albans",
      "st. pauls",
      "stamford hill",
      "stepney",
      "stevenage",
      "stockwell",
      "stoke newington",
      "stoke-on-trent",
      "strand",
      "stratford",
      "stratford upon avon",
      "surrey docks",
      "swindon",
      "swiss cottage",
      "telegraph hill",
      "temple mills",
      "thurrock",
      "tooting",
      "tower hill",
      "truro",
      "twickenham",
      "upper clapton",
      "vauxhall",
      "victoria",
      "walthamstow marshes",
      "wandsworth",
      "wapping",
      "warrington",
      "waterloo",
      "watford",
      "west brompton",
      "west ealing",
      "west ham",
      "west hampstead",
      "west kensington",
      "westminster",
      "white city",
      "whitechapel",
      "wigan",
      "wimbledon",
      "wimbledon park",
      "winchester",
      "windsor",
      "woking",
      "worcester",
      "worlds end",
      "york",
    ]);
  }, []);
  const getArea = async () => {
    // setLoading(true);
    const catRes = await getAreaApi();
    console.log(catRes);
    if (catRes?.status === 200) {
      const cusinesList = [];
      catRes.data.forEach((element) => {
        cusinesList.push(element.category_name);
      });
      setAreaList([...cusinesList]);

      //   setLoading(false);
    } else {
      ToastMessage("Somthing went wrong!", true);
      //   setLoading(false);
    }
  };
  return (
    <FormControl sx={{ marginRight: "12px" }}>
      <Select
        value={area}
        onChange={handleChange}
        displayEmpty
        className="listViewSelect"
        height="60px">
        <MenuItem value="">Area</MenuItem>
        {areaList.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {toUpperCaseEachWord(item)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default AreaDropDown;
