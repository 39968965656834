import React, { lazy } from "react";
import IsLoggedIn from "./auth/IsLoggedIn";
import Logout from "./pages/Logout";
import TermsCondition from "./pages/TermsCondition";
import Debug from "./pages/Debug";
import Homepage from "./pages/homepage/Homepage";
import NetNewVisitor from "./pages/homepage/NetNewVisitor";
import ListView from "./pages/homepage/ListView";
import Fav from "./pages/homepage/Fav";
import Details from "./pages/homepage/Details";
import SearchPage from "./components/homepage/SearchPage";
import LandingPage from "./pages/LandingPage";
import ExternalLink from "./pages/External";
import NewSignUp from "./pages/NewSignUp";

const PrivateRoute = lazy(() => import("./auth/PrivateRoute"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Terms = lazy(() => import("./pages/Terms"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const Root = lazy(() => import("./pages/Root"));
const Signup = lazy(() => import("./pages/Signup"));
const Cards = lazy(() => import("./pages/Cards"));
const Home = lazy(() => import("./pages/Home"));
const Favorite = lazy(() => import("./pages/Favorite"));
const Favorite2 = lazy(() => import("./pages/Favorite2"));
const Offer = lazy(() => import("./pages/Offer"));
const CatergoryOfferAll = lazy(() => import("./pages/CatergoryOfferAll"));
const Category = lazy(() => import("./pages/Categories"));
const Search = lazy(() => import("./pages/Search"));
const Savings = lazy(() => import("./pages/Savings"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Profile = lazy(() => import("./pages/Profile"));
const Location = lazy(() => import("./pages/Location"));
const TransactionHistory = lazy(() => import("./pages/TransactionHistory"));
const Legal = lazy(() => import("./pages/Legal"));
const OfferAll = lazy(() => import("./pages/OffersAll"));
const FavoritesAll = lazy(() => import("./pages/FavoritesAll"));
const GeneralForm = lazy(() => import("./pages/GeneralForm"));
const AllBrands = lazy(() => import("./pages/AllBrands"));
const PayementConfirmation = lazy(() => import("./pages/PayementConfirmation"));
export const routes = [
  {
    path: "/",
    name: "root",
    component:(
      <PrivateRoute>
        <Home allowed={["all"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/home",
    name: "home",
    component: (
      <PrivateRoute>
        <Home allowed={["all"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/homepage",
    name: "homepage",
    component: (
      <PrivateRoute>
        <Homepage allowed={["all"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/landingPage",
    name: "landingPage",
    component: <LandingPage />,
  },
  {
    path: "/paymentConfirmation/:brandName",
    name: "paymentConfirmation",
    component: <PayementConfirmation />,
  },
  {
    path: "/net-new-visitor",
    name: "net new visitor",
    component: (
      <PrivateRoute>
        <NetNewVisitor allowed={["all"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/list-view",
    name: "list view",
    component: (
      <PrivateRoute>
        <ListView allowed={["all"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/fav",
    name: "fav",
    component: (
      <PrivateRoute>
        <Fav allowed={["all"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/details/:brand",
    name: "details",
    component: (
      <PrivateRoute>
        <Details allowed={["all"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/search-page",
    name: "search page",
    component: (
      <PrivateRoute>
        <SearchPage allowed={["all"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/create-account",
    name: "sign up",
    component: (
      <IsLoggedIn>
        <Signup />
      </IsLoggedIn>
    ),
  },
  {
    path: "/login",
    name: "login",
    component: <Login allowed={["travx"]} />,
  },
  {
    path: "/learn-more",
    name: "learnMore",
    component: <LandingPage />,
  },
  {
    path: "/signout",
    name: "logout",
    component: <Logout allowed={["travx"]} />,
  },
  {
    path: "/signup",
    name: "signup",
    component: <NewSignUp />,
  },
  {
    path: "/reset-password",
    name: "reset password",
    component: <ForgotPassword allowed={["travx"]} />,
  },
  {
    path: "/privacy-policy",
    name: "privacy policy",
    component: <PrivacyPolicy allowed={["all"]} />,
    allowed: ["travx", "amex"],
  },
  {
    path: "/cookie-policy",
    name: "cookie policy",
    component: <CookiePolicy allowed={["all"]} />,
    allowed: ["travx", "amex"],
  },
  {
    path: "/terms-service",
    name: "terms service",
    component: <Terms allowed={["all"]} />,
    allowed: ["travx", "amex"],
  },
  {
    path: "/legals",
    name: "legals",
    component: <Legal allowed={["travx", "amex"]} />,
    allowed: ["travx", "amex"],
  },
  {
    path: "/favorites",
    name: "favorites",
    component: (
      <PrivateRoute>
        {/* <Favorite allowed={["travx", "amex"]} /> */}
        <Fav allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/favorites/category/:id",
    name: "favorites",
    component: (
      <PrivateRoute>
        {/* <Favorite allowed={["travx", "amex"]} /> */}
        <Fav allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/favorites/:id/:branchID",
    name: "favorites",
    component: (
      <PrivateRoute>
        <Favorite2 allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/find",
    name: "favorites",
    component: (
      <PrivateRoute>
        <Search allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/profile",
    name: "profile",
    component: (
      <PrivateRoute>
        <Profile allowed={["travx"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/savings",
    name: "savings",
    component: (
      <PrivateRoute>
        <Savings allowed={["travx"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/cards",
    name: "cards",
    component: (
      <PrivateRoute>
        <Cards allowed={["travx"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/transaction-history",
    name: "transaction history",
    component: (
      <PrivateRoute>
        <TransactionHistory allowed={["travx"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/location",
    name: "location",
    component: (
      <PrivateRoute>
        <Location allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/offer/all",
    name: "reccomended all",
    component: (
      <PrivateRoute>
        <OfferAll allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/offers/:id",
    name: "category offer all",
    component: (
      <PrivateRoute>
        <CatergoryOfferAll allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/favorites/all/:id",
    name: "Fav offer all",
    component: (
      <PrivateRoute>
        <FavoritesAll allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/offers/category/:id",
    name: " all offer",
    component: (
      <PrivateRoute>
        <Offer allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/category/all",
    name: "categories",
    component: (
      <PrivateRoute>
        <Category allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
    allowed: ["travx", "amex"],
  },
  {
    path: "/help",
    name: "Support",
    component: (
      <PrivateRoute>
        {/* <ExternalLink /> */}
        <GeneralForm allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/all-brands",
    name: "All Brands",
    component: (
      <PrivateRoute>
        <AllBrands allowed={["travx", "amex"]} />
      </PrivateRoute>
    ),
  },
  {
    path: "/terms",
    name: "Terms & Condition",
    component: <TermsCondition allowed={["amex"]} />,
  },
  {
    path: "/debug",
    name: "Debug",
    component: <Debug allowed={["amex"]} />,
  },
];
