import * as FingerprintJSPro from "@fingerprintjs/fingerprintjs-pro";
import { ToastMessage } from "../components/ToastMessage";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Cookies from "js-cookie";

export const FsService = (isGeo = "") => {
  const fpPromise = FingerprintJS.load();

  return fpPromise
    .then((fp) => fp.get({ extendedResult: true }))
    .then((result) => {
      const userInfo = {
        userId: result.visitorId,
        components: result.components,
      };

      // localStorage.setItem("FingerPrintData", JSON.stringify(userInfo.userId));
      Cookies.set("userId", userInfo.userId, { expires: 365 });

      // if (isGeo === "geo") {
      //   localStorage.setItem(
      //     "geoLocation",
      //     JSON.stringify({
      //       latitude: 51.510400,
      //       longitude: -0.130100,
      //     })
      //   );
      // }

      return userInfo;
    })
    .catch((err) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            localStorage.setItem(
              "geoLocation",
              JSON.stringify({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              })
            );
            localStorage.setItem("approxLocation", JSON.stringify(false));
          },
          () => {
            ToastMessage("Please provide location permission");
          }
        );
      }
    });
};

export const FsServicePro = (isGeo = "") => {
  const fpPromise = FingerprintJSPro.load({
    apiKey: "JFp3CwtM7gJqKCV9QNZn",
  });

  return fpPromise
    .then((fp) => fp.get({ extendedResult: true }))
    .then((result) => {
      const userInfo = {
        userId: result.visitorId,
      };

      // localStorage.setItem("FingerPrintDataPro", JSON.stringify(userInfo));

      // if (isGeo === "geo") {
      //   localStorage.setItem(
      //     "geoLocation",
      //     JSON.stringify({
      //       latitude: 51.510400,
      //       longitude: -0.130100,
      //     })
      //   );
      // }

      return userInfo;
    })
    .catch((err) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            localStorage.setItem(
              "geoLocation",
              JSON.stringify({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              })
            );
            localStorage.setItem("approxLocation", JSON.stringify(false));
          },
          () => {
            ToastMessage("Please provide location permission");
          }
        );
      }
    });
};
