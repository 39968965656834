import { Container, Stack, Typography } from "@mui/material";
import React, {useState, useEffect} from "react";
import Logo from "../../assets/homepage-logo.svg";

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Set the threshold width to consider the screen as mobile (adjust as needed)
      const mobileThreshold = 768;
      setIsMobile(window.innerWidth < mobileThreshold);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
    {!isMobile &&
      <Container maxWidth="lg">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderTop={{ sm: "1px solid #EAECF0", xs: "none" }}
          padding={"25px 0 48px"}
          gap={1.5}
        >
          <img src={Logo} alt="" />
          {/* <Typography color={"#667085"}>
            © 2023 Amexdining UI. All rights reserved.
          </Typography> */}
        </Stack>
      </Container>
    }
    </>
  );
};

export default Footer;
