import React, { useEffect, useState } from "react";
//import { useEffect, useRef, useState } from 'react';
import { useInView } from "react-intersection-observer";

import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { getOfferDetails } from "../services/HomeApi";
import FeedBack from "./FeedBack";
import Image from "./Image";
import { getUserInfo } from "../auth/index";
import { withCommas } from "../services/utility";
import { en } from "../appLabels/engLabels";
import { getBankName } from "../utils";
import { ToastMessage } from "./ToastMessage";
import ErrorBoundary from "../ErrorBoundary";

const HotelCard = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  useEffect(() => {
    setIsVisible(inView);
  }, [inView]);

  const [offerInfo, setOfferInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let branchID = props.obj[props.data];
    getDetails(props.data, branchID);
  }, [props.data]);

  const getDetails = async (id, branchID) => {
    try {
      setLoading(true);
      const user = getUserInfo();
      const obj = {
        customer_id: user.userId,
        offer_id: id,
        branch_id: branchID ? branchID : "",
      };

      if (props.getThumbnail) {
        obj.display_thumbnail = props.getThumbnail;
      }
      const apiRes = await getOfferDetails(obj);

      if (apiRes.status === 200) {
        setOfferInfo({ ...apiRes.data });
        if (props.card === "small") {
          let offerLocation = JSON.parse(localStorage.getItem("offerLocation"));
          let newLocation = {};
          newLocation[id] = {
            lat: apiRes.data.branch_latitude,
            lng: apiRes.data.branch_longitude,
          };
          if (offerLocation) {
            localStorage.setItem(
              "offerLocation",
              JSON.stringify({ ...offerLocation, ...newLocation })
            );
          } else {
            localStorage.setItem("offerLocation", JSON.stringify(newLocation));
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        ToastMessage("Something went wrong");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const GetCusine = (item, index = undefined) => {
    if (Object.keys(offerInfo).length && index === 0)
      return item.cuisines_list[index];
    if (Object.keys(offerInfo).length && item.cuisines_list[1]) {
      let t = [item.cuisines_list[0], item.cuisines_list[1]];

      return t.join(", ");
    } else {
      return item.cuisines_list.join(", ");
    }
  };

  return (
    <>
      {Object.keys(offerInfo).length === 0 ? (
        <Box className="d-flex justify-content-center">
          {loading ? (
            <CircularProgress />
          ) : (
            <Typography
              as="p"
              variant="h5"
              className="brandomtxt-regular mt-3 lt-0"
            >
              <CircularProgress />
            </Typography>
          )}
        </Box>
      ) : (
        <ErrorBoundary>
          <Card sx={{ borderRadius: "15px", boxShadow: "unset" }}>
            <Box sx={{ position: "relative" }}>
              <div ref={ref}>
                {isVisible ? (
                  <Image
                    src={offerInfo.offer_creatives}
                    alt={offerInfo.brand_name}
                    loading="lazy"
                    className={
                      props.card === "small"
                        ? `offer-card-image_small`
                        : `offer-card-image`
                    }
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#f3f3f3",
                    }}
                  ></div>
                )}
              </div>

              <Box
                className="position-absolute left-top-ali"
                sx={
                  offerInfo.display_your_bill_after_offer_percentage
                    ? { borderRadius: "50%" }
                    : { borderRadius: "8px !important" }
                }
              >
                <Typography
                  as="p"
                  variant={props.card ? "body5" : "body2"}
                  className={
                    props.card ? "location-card-dis brandombold" : "brandombold"
                  }
                >
                  {offerInfo.discount_percentage}%
                </Typography>
              </Box>

              <Box className="position-absolute right-top-ali">
                <Typography
                  as="p"
                  variant={props.card ? "body5" : "body4"}
                  color="#fff"
                  className="brandombold text-shadows"
                >
                  {withCommas(offerInfo.distance_from_user)} miles away
                </Typography>
              </Box>
              <Box className="position-absolute right-bottom-ali">
                {!props.card ? (
                  <FeedBack
                    offerId={props.data}
                    branchId={props.obj[props.data]}
                    like={offerInfo.like}
                    dislike={offerInfo.dislike}
                    favourite={offerInfo.favourite}
                  />
                ) : null}
              </Box>
              {offerInfo.trending && !props.card ? (
                <Box className="position-absolute left-bottom-ali">
                  <Typography as="p" variant="body5" color="#fff">
                    Trending
                  </Typography>
                </Box>
              ) : null}
            </Box>
            {props.card === "small" ? (
              <CardContent className="px-2 py-1">
                <Typography
                  as="p"
                  color="text.primary"
                  className="small-card brandombold"
                  sx={{
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {offerInfo.brand_name}{" "}
                  {offerInfo.view_all_branches
                    ? ` • ${offerInfo.branch_name}`
                    : ""}
                </Typography>
                <Typography
                  as="span"
                  color="text.primary"
                  className="small-card brandomtxt-regular mb-1"
                  sx={{ fontSize: "1.2vw" }}
                >
                  {offerInfo.price_range} • {GetCusine(offerInfo, 0)}{" "}
                  {offerInfo.restaurant_rating && offerInfo.num_reviews ? (
                    <>
                      •{" "}
                      <StarIcon
                        fontSize="10px"
                        sx={{
                          marginTop: "-4px",
                          color: "#8FA2AA",
                        }}
                      />{" "}
                      {offerInfo.restaurant_rating} (
                      {withCommas(offerInfo.num_reviews)})
                    </>
                  ) : null}
                </Typography>
              </CardContent>
            ) : (
              <CardContent className="smll" sx={{ padding: "1.1rem 8px" }}>
                <Typography
                  as="p"
                  variant="body2"
                  color="text.primary"
                  className="offer-brand brandombold"
                >
                  {offerInfo.brand_name}{" "}
                  {offerInfo.branch_name
                    ? offerInfo.brand_name === offerInfo.branch_name
                      ? ""
                      : ` • ${offerInfo.branch_name}`
                    : ""}
                </Typography>
                <Box className="offer-info mb-1">
                  <Typography
                    as="p"
                    variant="body4"
                    color="text.primary"
                    className="brandomtxt-regular price-range"
                  >
                    {offerInfo.price_range} •{" "}
                  </Typography>
                  <Typography
                    as="p"
                    variant="body4"
                    className="brandomtxt-regular offer-cusine"
                    color="text.primary"
                  >
                    {GetCusine(offerInfo, 0)}
                  </Typography>
                  {offerInfo.restaurant_rating && offerInfo.num_reviews ? (
                    <Typography
                      as="p"
                      variant="body4"
                      color="text.primary"
                      className="brandomtxt-regular offer-stars"
                    >
                      •{" "}
                      <StarIcon
                        sx={{
                          fontSize: "16px",
                          marginTop: "-4px",
                          color: "#8FA2AA",
                        }}
                      />
                      {offerInfo.restaurant_rating} (
                      {withCommas(offerInfo.num_reviews)})
                    </Typography>
                  ) : null}
                </Box>
              </CardContent>
            )}
          </Card>
        </ErrorBoundary>
      )}
    </>
  );
};

export default HotelCard;
