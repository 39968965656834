import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import CloseBtn from "../../assets/CloseBtn.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { withCommas } from "../../services/utility";
import FeedBack from "../FeedBack";
import Tooltip from "../Tooltip";
import { Mixpanel } from "../../services/MixPanel";

export const MapCardMobile = ({ offerInfo, setShowInfo, data, showInfo }) => {
  const key = Object.keys(data)[0];
  const bid = data[key];
  const route = useNavigate();

  // map card remove outside click
  const refMobile = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        showInfo &&
        refMobile.current &&
        !refMobile.current.contains(e.target)
      ) {
        setShowInfo(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showInfo]);

  ////.....

  const GetCusine = (item, index = undefined) => {
    if (Object.keys(offerInfo).length && index === 0)
      return item.cuisines_list[index];
    if (Object.keys(offerInfo).length && item.cuisines_list.length <= 2) {
      if (item.cuisines_list.length <= 1) {
        return (
          <Typography fontSize={14} color={"#344054"}>
            {item.cuisines_list[0]}
          </Typography>
        );
      }
      let t = [item.cuisines_list[0], item.cuisines_list[1]];
      let str = t.join(", ");
      return (
        <Typography fontSize={14} color={"#344054"}>
          {str}
        </Typography>
      );
    }

    let t = [item.cuisines_list[0], item.cuisines_list[1]];
    let allStr = item.cuisines_list.join(", ");
    let str = t.join(", ");
    return (
      <Tooltip content={allStr}>
        <Typography fontSize={14} color={"#344054"} className="hover-element">
          {str + " ..."}
        </Typography>
      </Tooltip>
    );
    //return item.cuisines_list.join(", ");
  };
  const DetailsPage = (id, obj) => {
    const key = Object.keys(id)[0];
    const user = JSON.parse(localStorage.getItem("userDetails"));
    Mixpanel.track("Hotel Card Selected", {
      userId: user.userId,
      offer_id: id,
      branch_id: key,
      location: "Favorite",
    });
    route(`/details/${key}/${id[key]}`);
  };
  return (
    <div
      className="mobileMapCard"
      ref={refMobile}
      style={{ border: "2px soild red" }}
      onClick={(e) => {
        if (e.target.className === 'closeImg') return;
        DetailsPage(data)
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className="recommendedBlueMobile"
        borderRadius={"12px"}
        bgcolor={"white"}
        boxShadow={"0px 6px 16px 0px rgba(0, 0, 0, 0.12)"}>
        <Stack direction={"row"}>
          <Box position={"relative"} marginRight={"10px"}>
            <img
              src={offerInfo.offer_creatives}
              alt={offerInfo.brand_name}
              className="mapCardMobileImg"
            />
            <Box
              bgcolor={"#F2F4F7"}
              borderRadius={"16px"}
              paddingX={"10px"}
              paddingY={"2px"}
              fontWeight={500}
              fontSize={"13px"}
              color={"#344054"}
              position={"absolute"}
              bottom={"8px"}
              left={"6px"}
              className="recommendedBlueDiscount">
              {offerInfo.discount_percentage}% Cashback
            </Box>
          </Box>

          <Box paddingY={1} className="mobileMapDetails">
            <Typography fontSize={14} fontWeight={600} color={"black"} mb={0.4}>
              {offerInfo.brand_name}
            </Typography>

            {offerInfo.restaurant_rating &&
            offerInfo.restaurant_rating >= 4 &&
            offerInfo.num_reviews ? (
              <Stack direction={"row"} alignItems="center" mb={0.5}>
                <StarIcon sx={{ fontSize: "16px", color: "black" }} />
                <Typography
                  fontSize={14}
                  color={"black"}
                  sx={{ paddingX: "4px" }}>
                  {offerInfo.restaurant_rating}
                </Typography>
                <Typography fontSize={14} color={"black"}>
                  ({withCommas(offerInfo.num_reviews)})
                </Typography>
              </Stack>
            ) : null}

            <Typography fontSize={14} color={"#344054"}>
              {offerInfo.branch_name}
            </Typography>
            <Stack direction="row" alignItems="center" mt={0.4}>
              <Typography fontSize={14} color={"#344054"}>
                {withCommas(offerInfo.distance_from_user)} miles
              </Typography>
              <Box
                width={2}
                height={2}
                bgcolor={"#344054"}
                borderRadius={"50%"}
                marginX={0.4}></Box>
              {GetCusine(offerInfo, 1)}
            </Stack>

            <Typography fontSize={14} fontWeight={600} color={"black"} mt={0.5}>
              {offerInfo.price_range}
            </Typography>
          </Box>
        </Stack>
        <Box position={"absolute"} top={"10px"} right={"10px"} color={"white"}>
          <FeedBack
            branchId={bid}
            favourite={offerInfo.favourite}
            like={offerInfo.like}
            dislike={offerInfo.dislike}
            offerId={key}
          />
        </Box>
        <Box
          className="closeBtn"
          position={"absolute"}
          top={"8px"}
          left={"8px"}
          color={"white"}
          onClick={() => {
            setShowInfo(false);
          }}>
          <img src={CloseBtn} alt="Close Button" className="closeImg" />
        </Box>
      </Stack>
    </div>
  );
};

const MapCard = ({ offerInfo, setShowInfo, data, showInfo }) => {
  const key = Object.keys(data)[0];
  const bid = data[key];
  const route = useNavigate();

  // map card remove outside click
  const ref = useRef();
  useEffect(() => {
    console.log("offerInfo", offerInfo);
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (showInfo && ref.current && !ref.current.contains(e.target)) {
        setShowInfo(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showInfo]);

  ////.....

  const GetCusine = (item, index = undefined) => {
    if (Object.keys(offerInfo).length && index === 0)
      return item.cuisines_list[index];
    if (Object.keys(offerInfo).length && item.cuisines_list.length <= 2) {
      if (item.cuisines_list.length <= 1) {
        return (
          <Typography fontSize={14} color={"#344054"}>
            {item.cuisines_list[0]}
          </Typography>
        );
      }
      let t = [item.cuisines_list[0], item.cuisines_list[1]];
      let str = t.join(", ");
      return (
        <Typography fontSize={14} color={"#344054"}>
          {str}
        </Typography>
      );
    }

    let t = [item.cuisines_list[0], item.cuisines_list[1]];
    let allStr = item.cuisines_list.join(", ");
    let str = t.join(", ");
    return (
      <Tooltip content={allStr}>
        <Typography fontSize={14} color={"#344054"} className="hover-element">
          {str + " ..."}
        </Typography>
      </Tooltip>
    );
    //return item.cuisines_list.join(", ");
  };
  const DetailsPage = (id, obj) => {
    const key = Object.keys(id)[0];
    const user = JSON.parse(localStorage.getItem("userDetails"));
    Mixpanel.track("Hotel Card Selected", {
      userId: user.userId,
      offer_id: id,
      branch_id: key,
      location: "Favorite",
    });
    route(`/details/${key}/${id[key]}`);
  };
  return (
    <Box
      className="mapCardContainer"
      ref={ref}
      boxShadow={"0px 6px 16px 0px rgba(0, 0, 0, 0.12)"}
      maxWidth={327}
      onClick={(e) => {
        if (e.target.className === 'closeImg') return;
        DetailsPage(data)
      }}
    >
      <Box position={"relative"}>
        <img
          src={offerInfo.offer_creatives}
          alt={offerInfo.brand_name}
          width={"100%"}
          style={{ borderRadius: "12px 12px 0 0" }}
        />
        <Box
          bgcolor={"#F2F4F7"}
          borderRadius={"16px"}
          paddingX={"10px"}
          paddingY={"2px"}
          fontWeight={500}
          fontSize={"14px"}
          color={"#344054"}
          position={"absolute"}
          bottom={"12px"}
          left={"12px"}>
          {offerInfo.discount_percentage}% Cashback
        </Box>
        <Box position={"absolute"} top={"16px"} right={"16px"} color={"white"}>
          <FeedBack
            branchId={bid}
            favourite={offerInfo.favourite}
            like={offerInfo.like}
            dislike={offerInfo.dislike}
            offerId={key}
          />
          {/* <FavoriteTwoToneIcon /> */}
        </Box>
        <Box
          className="closeBtn"
          position={"absolute"}
          top={"16px"}
          left={"16px"}
          onClick={() => {
            setShowInfo(false);
          }}
          style={{cursor: 'pointer', zIndex: 99999}}
        >
          <img src={CloseBtn} alt="Close Button" className="closeImg"/>
        </Box>
      </Box>
      <Box padding={1.5}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={0.5}>
          <Typography fontSize={14} fontWeight={600} color={"black"}>
            {offerInfo.brand_name}
          </Typography>

          {offerInfo.restaurant_rating &&
          offerInfo.restaurant_rating >= 4 &&
          offerInfo.num_reviews ? (
            <Stack direction="row" alignItems="center">
              <StarIcon sx={{ fontSize: "16px", color: "black" }} />
              <Typography
                fontSize={14}
                color={"black"}
                sx={{ paddingX: "4px" }}>
                {offerInfo.restaurant_rating}
              </Typography>
              <Typography fontSize={14} color={"black"}>
                ({withCommas(offerInfo.num_reviews)})
              </Typography>
            </Stack>
          ) : null}
        </Stack>

        <Stack direction="row" alignItems="center">
          {offerInfo.view_all_branches ? (
            <>
              <Typography fontSize={14} color={"#344054"}>
                {offerInfo.branch_name}
              </Typography>
              <Box
                width={2}
                height={2}
                bgcolor={"#344054"}
                borderRadius={"50%"}
                marginX={0.5}></Box>
            </>
          ) : null}

          <Typography fontSize={14} color={"#344054"}>
            {withCommas(offerInfo.distance_from_user)} miles
          </Typography>
        </Stack>
        {GetCusine(offerInfo, 1)}
        <Typography fontSize={14} fontWeight={600} color={"black"} mt={1}>
          {offerInfo.price_range}
        </Typography>
      </Box>
    </Box>
  );
};

export default MapCard;
