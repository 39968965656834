import React , {useEffect, useState ,useRef} from 'react';

const Tooltip = ({ content, children }) => {
    const refMobile =useRef();
    const [isVisible,setIsVisible] = useState(false);
//     useEffect(() => {
//       const checkIfClickedOutside = e => {
//         if (refMobile.current && !refMobile.current.contains(e.target)) {
//           isVisible(false)
//         }
//       }
  
//       document.addEventListener("mousever", checkIfClickedOutside)
  
//       return () => {
//         // Cleanup the event listener
//         document.removeEventListener("mouseout", checkIfClickedOutside)
//       }
//     }, [showInfo])

//     // Get the tooltip container element
// const tooltipContainer = document.querySelector('.tooltip-container');

// // Show the tooltip on hover
// tooltipContainer.addEventListener('mouseover', () => {
//   setIsVisible(true);
// });

// // Hide the tooltip when not hovering
// tooltipContainer.addEventListener('mouseout', () => {
//   setIsVisible(false);
// });

  return (
    <div  ref = {refMobile} className="tooltip-container">
      {children}
      <div className= "popUpBox">{content}</div>
      {/* <div ></div> */}
    </div>
  );
};

export default Tooltip;
