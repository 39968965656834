import {
	Button,
	Container,
	Select,
	styled,
	TextField,
	Pagination,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getBankName, neutralColor } from "../utils";

export const Contain = styled(Container)(({ theme }) => ({
	background:
		getBankName() === "travx"
			? neutralColor()
			: "fff",
	minHeight: "100vh",
	[theme.breakpoints.down("xs")]: {
		minHeight: "100vh",
	},
}));

export const SkipButton = styled(Button)(({ theme }) => ({
	// borderRadius:"1.125rem",
	fontSize: "1.125rem",
	border: "1px solid",
	borderColor: theme.palette.text.primary,
	"&:hover": {
		border: "2px solid",
		borderColor: theme.palette.text.primary,
	},
}));

export const LButton = styled(LoadingButton)(({ theme }) => ({
	fontSize: "1.25rem",
	padding: ".3em 2.5em",
	"&:hover": {
		backgroundColor: "#4B4C4E",
	},
	[theme.breakpoints.down("xs")]: {
		padding: ".2em 10vw !important",
	},
}));
export const LoginButton = styled(Button)(({ theme }) => ({
	fontSize: "1.28rem",
	padding: ".2em 2.5em",
	"&:hover": {
		backgroundColor: "#4B4C4E",
		color: "#fff"
	},
	"&[disabled]": {
		color: "#fff",
		cursor: "not-allowed",
		pointerEvents: "inherit",
		backgroundColor: "#b3b2b2",
		"&:hover": {
			backgroundColor: "#b3b2b2",
		},
	},
	[theme.breakpoints.down("xs")]: {
		padding: ".2em 10vw !important",
	},
}));
export const SignupButton = styled(Button)(({ theme }) => ({
	fontSize: "1.28rem",
	padding: ".2em 2.5em",
	[theme.breakpoints.down("xs")]: {
		padding: ".2em 10vw !important",
	},
}));

export const InputField1 = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiInputBase-root": {
		height: "45px",
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(15),
		borderRadius: "30px",
		background: "#fff",
		[theme.breakpoints.up("lg")]: {
			height: theme.typography.pxToVw(45),
			fontSize: theme.typography.pxToVw(15),
		},
	},
	"& .MuiOutlinedInput-notchedOutline": {
		height: "45px",
		borderColor: "transparent !important",

		[theme.breakpoints.up("lg")]: {
			height: theme.typography.pxToVw(45),
		},
	},
}));

export const InputField2 = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiInputBase-root": {
		height: "45px",
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(15),
		borderRadius: "30px",
		background: "#fff",
		[theme.breakpoints.up("lg")]: {
			height: theme.typography.pxToVw(45),
			fontSize: theme.typography.pxToVw(15),
		},
	},
	"& .MuiOutlinedInput-notchedOutline": {
		height: "45px",
		borderColor: "transparent !important",

		[theme.breakpoints.up("lg")]: {
			height: theme.typography.pxToVw(45),
		},
	},
	border: "1.5px solid #58595B",
	borderRadius: "25px",
}));

export const Code = styled(Select)(({ theme }) => ({
	borderRadius: "25px",
	border: "1.5px solid #58595B",
	height: "50px",
	"& .MuiOutlinedInput-notchedOutline": {
		display: "none",
	},
}));
export const Code2 = styled(Select)(({ theme }) => ({
	border: "1px solid #d9d9d9",
	height: "43px",
	"& .MuiOutlinedInput-notchedOutline": {
		display: "none",
	},
}));

export const InputField3 = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiInputBase-root": {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(15),
		borderRadius: "30px",
		background: "#fff",
		[theme.breakpoints.up("lg")]: {
			fontSize: theme.typography.pxToVw(15),
		},
	},
	"& .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent !important",
	},
	border: "1.5px solid #58595B",
	borderRadius: "25px",
}));

export const InputField = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiInputBase-root": {
		height: "27px",
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(15),
		[theme.breakpoints.up("lg")]: {
			height: theme.typography.pxToVw(27),
			fontSize: theme.typography.pxToVw(15),
		},
	},
	"& .MuiOutlinedInput-notchedOutline": {
		height: "35px",
		borderColor: "#D9D9D9",
		[theme.breakpoints.up("lg")]: {
			height: theme.typography.pxToVw(35),
		},
	},
}));
export const SupportField = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiInputBase-root": {
		height: "38px",
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(15),
		[theme.breakpoints.up("lg")]: {
			height: theme.typography.pxToVw(38),
			fontSize: theme.typography.pxToVw(15),
		},
	},
	"& .MuiOutlinedInput-notchedOutline": {
		height: "40px",
		borderColor: "#D9D9D9",
		[theme.breakpoints.up("lg")]: {
			height: theme.typography.pxToVw(40),
		},
	},
}));

export const Modalstyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "auto",
	bgcolor: "background.paper",
	p: 3,
	borderRadius: "20px",
	"&:focus": {
		outline: "none",
	},
	"& .MuiModal-backdrop": {
		backgroundColor: "rgb(206,233,239)",
	},
};
export const PageRow = styled(Select)(({ theme }) => ({
	color: getBankName()==='travx' ? "#000":"#fff",
	height: "26px",
	"& .MuiOutlinedInput-notchedOutline": {
	borderColor: getBankName() ==='travx'? "#000":"#fff",

  },
	"& .MuiSvgIcon-root": {
		display: "none",
		[theme.breakpoints.up("xs")]:{
			display:"block"
		}
	},
}));

export const CustomSelect = styled(Select)(({ theme }) => ({
	"& .MuiOutlinedInput-notchedOutline": {
		border: "unset",
	},
}));

export const CustomPagination = styled(Pagination)(({ theme }) => ({
	"& .MuiButtonBase-root": {
		color: "#000"// getBankName() === "travx" ? "#000" : "#000",// theme.palette.common.white,
	},
	"& .MuiPagination-ul li": {
		marginBottom: "8px",
	},
}));

export const TopSearch = styled(TextField)(({ theme }) => ({
	width: "100%",
	"& .MuiInputBase-root": {
		color: theme.palette.text.secondary,
		fontSize: theme.typography.pxToRem(18),
		borderRadius: "30px",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		borderRadius: "30px",
		borderColor: "#D9D9D9",
	},
}));
