import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { categoryApi } from "../../services/HomeApi";
import { ToastMessage } from "../ToastMessage";

const CusinesDropDown = (props) => {
	const [cusines, setCusines] = useState([]);
	const [cusinesList, setCusinesList] = useState([]);

	const handleChange = (e) => {
		const val = e.target.value;
		setCusines(val);
		props.updateCusines(val);
	};
	useEffect(()=>{
		getCategory();
	},[])
	const getCategory = async () => {
		// setLoading(true);
		const catRes = await categoryApi({
		  view_all: true,
		});
	
		if (catRes?.status === 200) {
		  const cusinesList = []
		  catRes.data.forEach(element => {
			cusinesList.push(element.category_name)
		  });
		  setCusinesList([...cusinesList]);
		//   setLoading(false);
		} else {
		  ToastMessage("Somthing went wrong!", true);
		//   setLoading(false);
		}
	  };
	return (
		<FormControl sx={{ marginRight: "12px" }}>
			<Select
				value={cusines}
				onChange={handleChange}
				displayEmpty
				className="listViewSelect"
				height='60px'
			>
				<MenuItem value="">Cuisines</MenuItem>
				{cusinesList.map((item) => {
					return (
						<MenuItem key={item} value={item}>{item}</MenuItem>
					)
					})
				}
			</Select>
		</FormControl>

	)
}
export default CusinesDropDown;
