import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/homepage/Header";
import GetDiscount from "../../components/homepage/GetDiscount";
import Recommended from "../../components/homepage/NewRecommended";
import FoodType from "../../components/homepage/FoodType";
import SaveMoney from "../../components/homepage/SaveMoney";
import Footer from "../../components/homepage/Footer";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  CircularProgress,
} from "@mui/material";
import "../../components/homepage/homepage.css";

import Slider from "../../components/newSlider";
import { SwiperSlide } from "swiper/react";

import { getUserInfo } from "../../auth";

import { getOffers } from "../../services/HomeApi";

import ErrorBoundary from "../../ErrorBoundary";
import { en } from "../../appLabels/engLabels";
import HotelCard from "../../components/HotelCard";
import BottomMenu from "../../components/homepage/BottomMenu";

const NetNewVisitor = () => {
  const [loading, setLoading] = useState(false);
  const [nearLocationRecords, setNearLocationRecords] = useState([]);
  const [loc, setLoc] = useState(false);
  const route = useNavigate();
  const [locationUpdated, setLocationUpdated] = useState('');

  useEffect(() => {
    successLocation();
  }, [locationUpdated]);

  const successLocation = async () => {
    setLoading(true);
    const user = getUserInfo();
    if (user) {
      const offerBody = {
        customer_id: user.userId,
        view_all: false,
        sorted_by: "Distance",
      };
      try {
        const apiRes = await getOffers(offerBody);

        if (apiRes.status === 200) {
          setNearLocationRecords([...apiRes.data.offer_rest_mapping]);
          setLoading(false);
        }
      } catch (error) {}
    }
  };
  const DetailsPage = (id) => {
    const key = Object.keys(id)[0];
    const user = JSON.parse(localStorage.getItem("userDetails"));
    // Mixpanel.track("Hotel Card Selected", {
    //   userId: user.userId,
    //   offer_id: id,
    //   branch_id: key,
    //   location: 'Home'
    // });
    route(`/details/${key}/${id[key]}`);
  };
  const updateOffer = (val) => {
    setLocationUpdated(val)
  }
  return (
    <>
      <Header />
      <Box className="viewFooter">
      <GetDiscount updateOffer={updateOffer}/>

      <Container maxWidth="lg">
        <Box mb={8}>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={3}
          >
            <Typography
              fontWeight={600}
              fontSize={{ xs: 20, sm: 36 }}
              lineHeight={{ xs: "30px", sm: "44px" }}
              letterSpacing={"-0.72px"}
              color={"#101828"}
            >
              Near you
            </Typography>
            <Link
              href="/offer/all"
              fontWeight={600}
              fontSize={18}
              color={"#006FCF"}
              underline="none"
              className="viewAllLinkDesktop"
            >
              View All
            </Link>
          </Grid>

          {!loading ? (
            <>
              {nearLocationRecords.length > 0 ? (
                <ErrorBoundary>
                  <Slider>
                    {nearLocationRecords.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={Object.keys(item)[0]}
                          onClick={() => DetailsPage(item)}
                        >
                          {loading ? (
                            <Box className="d-flex justify-content-center">
                              <CircularProgress />
                            </Box>
                          ) : (
                            <>
                              <Recommended
                                data={Object.keys(item)[0]}
                                obj={item}
                              />
                            </>
                          )}
                        </SwiperSlide>
                      );
                    })}
                  </Slider>
                </ErrorBoundary>
              ) : (
                <Typography
                  as="p"
                  variant="h5"
                  className="brandomtxt-regular mt-3 lt-0"
                >
                  {en.noOfferFound}
                </Typography>
              )}
            </>
          ) : (
            <Box className="d-flex justify-content-center">
              <CircularProgress />
            </Box>
          )}

          <Grid container className="viewAllBtnMobile">
            <Grid item xs={12} width={"100%"}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: 48,
                  borderRadius: "8px",
                  background: "#006FCF",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  fontWeight: 600,
                  fontSize: 16,
                  marginTop: "32px",
                }}
              >
                View All
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* Cuisines */}
        <Box mb={12}>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={3}
          >
            <Typography
              fontWeight={600}
              fontSize={{ xs: 20, sm: 36 }}
              lineHeight={{ xs: "30px", sm: "44px" }}
              letterSpacing={"-0.72px"}
              color={"#101828"}
            >
              Cuisines
            </Typography>
            <Link
              href="/category/all"
              fontWeight={600}
              fontSize={18}
              color={"#006FCF"}
              underline="none"
              className="viewAllLinkDesktop"
            >
              View All
            </Link>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} width={"100%"}>
              <FoodType cuisinesText={"Vegetarian Friendly"} />
            </Grid>
            <Grid item xs={12} sm={4} width={"100%"}>
              <FoodType cuisinesText={"Burger"} />
            </Grid>
            <Grid item xs={12} sm={4} width={"100%"}>
              <FoodType cuisinesText={"Italian"} />
            </Grid>
            <Grid item xs={12} sm={4} width={"100%"}>
              <FoodType cuisinesText={"Sweet Treats"} />
            </Grid>
            <Grid item xs={12} sm={4} width={"100%"}>
              <FoodType cuisinesText={"Asian"} />
            </Grid>
            <Grid item xs={12} sm={4} width={"100%"}>
              <FoodType cuisinesText={"Steak"} />
            </Grid>
          </Grid>

          <Grid container className="viewAllBtnMobile">
            <Grid item xs={12} width={"100%"}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  height: 48,
                  borderRadius: "8px",
                  background: "#006FCF",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  fontWeight: 600,
                  fontSize: 16,
                  marginTop: "32px",
                }}
              >
                View All
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <SaveMoney />

      <Footer />
      </Box>
      <BottomMenu />
    </>
  );
};

export default NetNewVisitor;
