import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import SaveMoney1 from "../../assets/client/New-Soho-Coffee-Co.png";
import SaveMoney2 from "../../assets/client/gordon-ramsay.png";
import SaveMoney3 from "../../assets/client/honest.png";
import SaveMoney4 from "../../assets/client/yochi.png";
import SaveMoney5 from "../../assets/client/krispy.png";
import SaveMoney6 from "../../assets/client/gochu.jpg";

const SaveMoney = () => {
  return (
    <Container maxWidth="lg" className="removeContainerPadding">
      <Box
        bgcolor={"#F9FAFB"}
        borderRadius={"16px"}
        padding={8}
        mb={{ xs: 0, sm: 12.5 }}
      >
        <Typography
          className="text-center"
          fontSize={18}
          color={"#475467"}
          mb={4}
        >
          Save money on over 100 restaurants...{" "}
        </Typography>
        <Grid container spacing={6} justifyContent={"center"} alignItems={'center'}>
          <Grid item xs={12} sm={2}>
            <img src={SaveMoney1} alt="SaveMoney1" className="saveMoneyImage" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <img src={SaveMoney2} alt="SaveMoney2" className="saveMoneyImage" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <img src={SaveMoney3} alt="SaveMoney3" className="saveMoneyImage" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <img src={SaveMoney4} alt="SaveMoney4" className="saveMoneyImage" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <img src={SaveMoney5} alt="SaveMoney5" className="saveMoneyImage" />
          </Grid>
          <Grid item xs={12} sm={2}>
            <img src={SaveMoney6} alt="SaveMoney6" className="saveMoneyImage" />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SaveMoney;
